import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, computed, observable, reaction } from 'mobx';
import { observer } from 'mobx-react'
import { Checkbox, Icon, Table, Confirm, Popup } from 'semantic-ui-react'
import styled from 'styled-components';
import { ErrorLabel } from 'spider/semantic-ui/Target'
import Decimal from 'decimal.js'
import Batches from 'container/Perform/Step/Form/Batches'
import { SerialNumber } from '../TargetInfoModal/TargetDescription'
import { FullWidthTable } from './FormStep'

// components
import TargetDecimalPlusMinusInput from './DecimalPlusMinusInput';
// end components

// helpers
import { humanReadable } from '../../helpers/decimal';
import { isMyWorkstation, isUnknownWorkstation } from './helpers'
import getGlobalValue from 'helpers/getGlobalValue'
// end helpers

// stores
import { Step } from 'store/Step';
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion';
import { ProductionRequest } from 'store/ProductionRequest'
// end stores

const BatchInfo = styled.div`
  display: flex;
  padding: 5px;
`


/**
 * Contains the actual table of the material plan to be executed
 */
@observer
export class MaterialPlanMaterialsTable extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest),
    materialPlan: PropTypes.instanceOf(BillOfMaterialVersion).isRequired,
    step: PropTypes.instanceOf(Step).isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.number.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    targetProps: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    getErrors: PropTypes.func.isRequired,
    variableQuantity: PropTypes.func.isRequired,
    generalErrors: PropTypes.array,
    batchSize: PropTypes.number,
  }

  static defaultProps = {
    generalErrors: [],
    getErrors: (bomItemId) => [],
    batchSize: 1,
  }

  @observable showDeletionConfirm = null
  @observable scannedUnsavedBatches = {}
  @observable DECIMAL_ROUND_PRECISION = 3

  @observable batchRequirements = {}

  constructor(...args) {
    super(...args)
    this.getMaterialPlanDecimalRounding()

    const { materialPlan } = this.props;
    this.renderMaterialPlan = this.renderMaterialPlan.bind(this)
    this.setBatchRequired = this.setBatchRequired.bind(this)

    // eslint-disable-next-line no-unused-vars
    for (const item of materialPlan.items.filter(item => item.type === 'material')) {
      const savedDetails = item.details.filter(detail => detail.quantityFinished)
      if (savedDetails.length > 0) {
        // collect after save
        this.batchRequirements[item.id] = savedDetails.reduce(
          (total, detail) => total.add(detail.quantityFinished ? detail.quantityFinished : Decimal(0)),
          Decimal(0)
        )
      } else {
        // nothing saved yet
        this.batchRequirements[item.id] = this.calculateBatchRequires(item)
      }
      // maintain here for intermediate save
      item.taskProgress = this.batchRequirements[item.id]
    }
  }

  calculateBatchRequires(item) {
    const { productionRequest, batchSize } = this.props;
    let value = 0
    if (item.requiredQuantity) {
      value = Decimal(item.requiredQuantity / productionRequest.quantity)
    } else {
      value = Decimal(item.quantityBatch ?? item.quantity)
    }
    // 1 is the rounding mode, in this case ROUND_DOWN
    // check out https://mikemcl.github.io/decimal.js/#modes
    return Decimal(value * batchSize).toDecimalPlaces(6, 1)
  }

  async getMaterialPlanDecimalRounding() {
    this.DECIMAL_ROUND_PRECISION = await getGlobalValue('material_plan_decimal_rounding')
  }

  componentDidMount() {
    this.productionRequestQuantityReaction = reaction(
      () => this.props.variableQuantity,
      action((quantity) => {
        // Should change 'required for this batch' if quantity formfield (this.props.variableQuantity) is changed
        const { materialPlan } = this.props;

        materialPlan.items.filter(item => item.type === 'material').forEach((item) => {
          this.batchRequirements[item.id] = this.calculateBatchRequires(item)
          this.setBatchRequired(item, this.batchRequirements[item.id])
        })
      }),
    )
  }

  componentWillUnmount() {
    this.productionRequestQuantityReaction()
  }

  batchesScanOnChange(value, materialPlanItem) {
    const { onChange } = this.props;
    onChange(materialPlanItem, value, null, null)
    this.scannedUnsavedBatches[materialPlanItem.id] = value
  }

  getIntermediateBatches(item) {
    // collect all batches that are "intermediate" SAVED but not yet CONFIRMED
    return (item.details.models
      .filter(({ value, batches }) => !value && batches.length > 0)
      .flatMap(({ batches }) => batches)
    )
  }

  getIntermediateQuantity(item) {
    return (
      item.details.models
        .filter(({ value }) => !value)
        .reduce((total, itemDetail) =>
          total.add(itemDetail.batches.reduce((subTotal, batch) => subTotal.add(this.getQuantityUsed(batch)), Decimal(0))),
          Decimal(0),
        )
    )
  }

  getScannedBatches(item) {
    // collect all batches that are SAVED and CONFIRMED
    const batches = []
    // eslint-disable-next-line
    for (const detailMaterialtask of item.details.models) {
      if (detailMaterialtask.detail.components.length > 0) {
        // Actual step performance
        // eslint-disable-next-line
        for (const component of detailMaterialtask.detail.components.models) {
          if (component.batchUsings.at(0).usedBatch.batchType.articleType.id === item.articleType.id) {
            batches.push(component.batchUsings.at(0).usedBatch)
          }
        }
      }
    }
    return batches
  }

  getScannedBatchesQuantity(item) {
    return (
      item.details.models.reduce((total, itemDetail) =>
        total.add(itemDetail.detail.components.models.reduce((subTotal, component) =>
          component.batchUsings.at(0).usedBatch.batchType.articleType.id === item.articleType.id ?
            subTotal.add(component.batchUsings.at(0).quantity) : subTotal.add(Decimal(0))
          , Decimal(0))
        ), Decimal(0))
    )
  }

  @computed get batchCollected() {
    const { materialPlan } = this.props;
    const batchCollected = {}

    // eslint-disable-next-line
    for (const item of materialPlan.items.filter(item => item.type === 'material')) {
      let unsavedSum
      if (this.scannedUnsavedBatches[item.id]) {
        unsavedSum = this.scannedUnsavedBatches[item.id].reduce((total, { usage }) => total.add(usage ? usage : 0), Decimal(0))
      } else {
        unsavedSum = Decimal(0)
      }

      // collect all batches that are "intermediate" SAVED but not yet CONFIRMED
      const intermediateSum = this.getIntermediateQuantity(item)

      // collect all batches CONFIRMED to this line, and sum the batch usages
      const savedSum = this.getScannedBatchesQuantity(item)

      batchCollected[item.id] = {
        unsavedSum: unsavedSum,
        intermediateSum: intermediateSum,
        savedSum: savedSum,
        totalSum: unsavedSum.add(savedSum).add(intermediateSum)
      }
    }

    return batchCollected
  }


  renderBatchesScan(materialPlanItem, quantity) {
    const { productionRequest, value, onConfirm, getErrors, targetProps } = this.props;

    const quantityToCollect = Decimal(0).add(quantity).sub(this.batchCollected[materialPlanItem.id]['intermediateSum'])

    return (
      <Batches data-test-batches-item={materialPlanItem.id}
        {...targetProps}
        articleType={materialPlanItem.articleType}
        quantity={quantityToCollect > 0 ? quantityToCollect : 0}
        value={value}
        errors={getErrors(materialPlanItem.id.toString())}
        onChange={(value) => this.batchesScanOnChange(value, materialPlanItem)}
        onConfirm={(value) => onConfirm(materialPlanItem)}
        allowStorageLocationSelection={true}
        currentWarehouse={productionRequest.productionOrder.warehouse}
        rounding={this.DECIMAL_ROUND_PRECISION}
        showWarehouse={materialPlanItem?.forcedBackflush}
      />
    )
  }

  renderPerformMaterial(materialPlanItem, itemChecked, quantity) {
    const scannedBatches = this.getScannedBatches(materialPlanItem)
    const intermediateSavedBatches = this.getIntermediateBatches(materialPlanItem)
    if (scannedBatches.length > 0 || intermediateSavedBatches.length > 0) {
      return this.renderScannedBatches(materialPlanItem, scannedBatches, intermediateSavedBatches)
    }

    if (!materialPlanItem.backflush) {
      return this.renderBatchesScan(materialPlanItem, quantity)
    }

    return (
      <Checkbox toggle data-test-materials-item={materialPlanItem.id}
        checked={itemChecked}
        onChange={(e, { checked }) => {
          materialPlanItem.backflush = false
          materialPlanItem.forcedBackflush = true
        }}
      />
    )
  }

  getRequiredByBatch(item) {
    const { productionRequest, batchSize } = this.props;
    return Decimal(
      (item.requiredQuantity ?
        (item.requiredQuantity / productionRequest.quantity) :
        (item.quantityBatch ?? item.quantity)
      ) * batchSize,
    )
  }

  getSerialNumber(batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      return batch.serialNumber
    }
    return batch['serial_number']
  }

  getQuantityUsed(batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      return batch.quantity //batch.batchUseds.models.reduce((total, { quantity }) => total.add(quantity), Decimal(0))
    }
    return batch['usage'] ? batch['usage'] : 0
  }

  getStorageLocation(articleTypeStorageLocations, batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      const ats = articleTypeStorageLocations.find(asl => asl.storageLocation.id === batch.storageLocation.id)
      const stockSuffix = ats ? ` (${humanReadable(ats.stock, this.DECIMAL_ROUND_PRECISION)})` : ''
      return batch.storageLocation.code + stockSuffix
    }
    else {
      return batch['storage_location']
    }
  }

  getPRWarehouseStock(articleType) {
    if (articleType?.storageLocations?.models && this.props?.productionRequest?.productionOrder?.warehouse) {
      const storageLocations = articleType.storageLocations.models.filter(sl => sl.warehouse.code === this.props.productionRequest.productionOrder.warehouse.code)
      const stock = storageLocations.reduce((sum, sl) => sum.add(sl.stock), Decimal(0))
      return stock
    }
    else return Decimal(0)
  }

  async onRemoveMaterialDetails(materialPlanItem) {
    // The backend makes sure there is always one temp/active detail_material_task item
    const detailMaterialtask = materialPlanItem.details
      .find(detailMaterial => detailMaterial.billOfMaterialItem.id === materialPlanItem.id)

    if (detailMaterialtask) {
      await detailMaterialtask.delete()
    }
  }

  renderBatch(materialPlanItem, batch) {
    const serialNumber = this.getSerialNumber(batch)
    const quantityUsed = humanReadable(this.getQuantityUsed(batch), this.DECIMAL_ROUND_PRECISION)
    return (
      <BatchInfo data-test-scanned-batches>
        <SerialNumber data-test-serial-number={serialNumber}>
          {batch.id && `${batch.id} |`} {quantityUsed} | {serialNumber} | {this.getStorageLocation(materialPlanItem.articleType.storageLocations, batch)}
        </SerialNumber>
        <label>&nbsp;</label>
        <Icon data-test-delete-material-details-scanned-batch={materialPlanItem.id}
          name="delete"
          size="large"
          style={{ cursor: 'pointer' }}
          onClick={() => this.showDeletionConfirm = materialPlanItem.id}
        />
        <Confirm
          open={this.showDeletionConfirm === materialPlanItem.id}
          header={t('formStepField.field.materialPlan.confirmDeletion.header', { description: materialPlanItem.description })}
          content={t('formStepField.field.materialPlan.confirmDeletion.content')}
          confirmButton={t('formStepField.field.materialPlan.confirmDeletion.confirmButton')}
          onCancel={() => this.showDeletionConfirm = null}
          onConfirm={action(async () => {
            this.showDeletionConfirm = null
            this.onRemoveMaterialDetails(materialPlanItem)
          })}
        />
      </BatchInfo>
    )
  }

  renderScannedBatches(materialPlanItem, scannedBatches, intermediateSavedBatches) {
    const requiredQuantityForBatch = humanReadable(this.getRequiredByBatch(materialPlanItem))

    return (
      <>
        {scannedBatches.length > 0 && scannedBatches.map(batch => this.renderBatch(materialPlanItem, batch))}
        {intermediateSavedBatches.length > 0 && (
          <>
            <i>Temporaly saved batches:</i>
            {intermediateSavedBatches.map(batch => this.renderBatch(materialPlanItem, batch))}
          </>
        )}
        {this.renderBatchesScan(materialPlanItem, requiredQuantityForBatch)}
      </>
    )
  }

  setBatchRequired(item, val) {
    const { onChange } = this.props
    const min = Decimal(0)

    if (Decimal(val).lte(min)) {
      this.batchRequirements[item.id] = min
    } else {
      this.batchRequirements[item.id] = Decimal(val)
    }
    onChange(item, null, null, this.batchRequirements[item.id])
  }

  getRequiredQuantityForWholeOrder(item, required) {
    let requiredQuantity = Decimal(0)
    requiredQuantity = Decimal(item.requiredQuantity ?? ((item.quantityBatch ?? item.quantity) * required))

    return humanReadable(requiredQuantity, this.DECIMAL_ROUND_PRECISION)
  }

  renderMaterialPlan(materialPlanItem) {
    const { required, value, productionRequest } = this.props;

    const requiredQuantityForWholeOrder = this.getRequiredQuantityForWholeOrder(materialPlanItem, required)

    const requiredQuantityForBatch = this.getRequiredByBatch(materialPlanItem)
    const issued = productionRequest.materialIssues
      .filter(materialIssue => materialIssue.articleType.id === materialPlanItem.articleType.id)
      .reduce((sum, issue) => sum.plus(Decimal(issue.quantity)), Decimal(0)).plus(
        this.getIntermediateQuantity(materialPlanItem) //Intermediate saved batches are here
      )
    const warehouseStock = this.getPRWarehouseStock(materialPlanItem.articleType) ?? Decimal(0)

    const itemChecked = value[materialPlanItem.id] || false
    return (
      // if the item is handled by the ERP, gray out the line
      <Table.Row disabled={materialPlanItem.handledByErp} material-plan-item={materialPlanItem.id}>
        <Table.Cell collapsing>
          <b>{materialPlanItem.number + '. '}</b>
          {materialPlanItem.articleType.code && materialPlanItem.articleType.getLink()}
          {materialPlanItem.description === materialPlanItem.articleType.name ? '' : ` ${materialPlanItem.articleType.name}`}
          {' ' + materialPlanItem.description}
        </Table.Cell>
        <Table.Cell collapsing>
          <Icon size="big" name={materialPlanItem.backflush ? 'check square' : 'square outline'} />
        </Table.Cell>
        <Table.Cell collapsing data-test-batch-required-quantity={materialPlanItem.id}>
          <TargetDecimalPlusMinusInput
            key={`${materialPlanItem.id}-${requiredQuantityForBatch}`} // Make sure that react-text-mask re-applies props when changing capMax.
            id={materialPlanItem.id}
            capMin={0}
            capMax={requiredQuantityForBatch}
            target={materialPlanItem}
            setTarget={this.setBatchRequired}
            value={this.batchRequirements[materialPlanItem.id]}
            inputWidth={6}
            buttonWidth={4}
            disableMax={true}
            disableColors={true}
            // additional LimitedDecimalInput props
            placeholder={t('workStation.production.performModal.scan.quantity')}
            decimalSymbol="."
            thousandsSeparatorSymbol=","
            decimalLimit={this.DECIMAL_ROUND_PRECISION}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          {this.batchRequirements[materialPlanItem.id].gt(warehouseStock) &&
            <Popup data-test-stock-warning-text={materialPlanItem.id}
              content={t('formStepField.field.materialPlan.stockWarning',
                {
                  warehouse: productionRequest?.productionOrder?.warehouse?.code,
                  stock: humanReadable(warehouseStock, this.DECIMAL_ROUND_PRECISION)
                })}
              trigger={<Icon data-test-stock-warning={materialPlanItem.id} color='orange' size='large' name='exclamation triangle' />} />
          }
        </Table.Cell>
        <Table.Cell collapsing data-test-issued-quantity={materialPlanItem.id} colSpan={0}>
          {humanReadable(issued, this.DECIMAL_ROUND_PRECISION)}
        </Table.Cell>
        <Table.Cell data-test-item-perform={materialPlanItem.id}>
          {this.renderPerformMaterial(materialPlanItem, itemChecked, this.batchRequirements[materialPlanItem.id])}
        </Table.Cell>
        <Table.Cell collapsing data-test-done={materialPlanItem.id}>
          {/*
          The calculation below should represent the total materials to do for the entire PR, not only the current batch
          That means that if you have to make 10 bikes each with 2 wheels, you have finished 2 bike and from the 3rd
          you have assembled 1 wheel, this indicator should show 5/20
           */}
          <h4>{`${humanReadable(this.batchCollected[materialPlanItem.id]['totalSum'], this.DECIMAL_ROUND_PRECISION)}/${requiredQuantityForWholeOrder}`}</h4>
        </Table.Cell>
      </Table.Row>
    )
  }

  state = { toggled: false }

  handleClick = (e) => {
    this.setState(prevState => ({
      toggled: !prevState.toggled
    }));
  }

  render() {
    const { materialPlan, step, generalErrors } = this.props;
    const hasItems = materialPlan.items.models.some(item => item.type === 'material' && (isUnknownWorkstation(item) || isMyWorkstation(step.workStation.code, item)))

    //Table for Material Plan Materials
    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell>{t('formStepField.field.articleType.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.backflush.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.requiredForThisBatch.label')}</Table.HeaderCell>
        <Table.HeaderCell colSpan={0}></Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.issued.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.checklist.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.finished.label')}</Table.HeaderCell>
      </Table.Row>
    )

    return (
      <FullWidthTable padded basic='very'>
        { // if there are any linked items, render the header as well
          hasItems ?
            <Table.Header>{tableHeader}</Table.Header>
            : //else, display a message saying there are no linked tasks/materials
            <Table.Row>
              <Table.Cell colSpan='8'>
                {t('formStepField.field.materialPlan.noMaterials')}
              </Table.Cell>
            </Table.Row>
        }
        <tbody data-material-plan-table={'material'}>
          {materialPlan.items
            .filter(item => item.type === 'material' && (isUnknownWorkstation(item) || isMyWorkstation(step.workStation.code, item)))
            .map(this.renderMaterialPlan)}
        </tbody>
        {generalErrors.length > 0 && (
          <ErrorLabel>
            {generalErrors.map(({ message }, i) => <div key={i}>{message}</div>)}
          </ErrorLabel>
        )}
      </FullWidthTable>
    )
  }
}

