import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BatchType } from 'store/BatchType';
import FormGroup from 'spider/semantic-ui/FormGroup'

import { ProcessVersion } from 'store/ProcessVersion';
import { action, computed, observable, reaction } from 'mobx';
import { isFeatureFlagEnabled } from 'helpers/featureFlags';
import { GlobalValue } from 'store/GlobalValue';
import SmallContent from 'component/SmallContent';
import { Button, Form, Header, Icon, Message, Popup } from 'semantic-ui-react';
import { openCss } from 'component/Tooltip'
import Scrollbars from 'react-custom-scrollbars'
import TargetSerialNumberFormat from 'component/TargetSerialNumberFormat';
import { FormLabel, InfoIcon, TargetCheckbox, TargetNumberInput, TargetRadioButtons, TargetSelect, TargetTextInput } from 'spider/semantic-ui/Target';
import Tabs from 'component/Tabs';
import { getStepBatchSizes } from 'container/ArticleType/Edit';
import { CapabilityStore } from 'store/Capability';
import RightDivider from 'spider/component/RightDivider';
import WorkStationSelect from 'component/WorkStationSelect';
import StepProcessTiming from 'feature/OEE/Components/StepProcessTiming/StepProcessTiming';
import { shortNumber } from 'helpers';
import Tooltip from 'component/Tooltip';
import Steps, { stepIcon } from 'component/Steps';
import { theme } from 'styles';
import { EmptyMessageContainer } from 'component/AdminOverview';
import Sections from 'component/Sections';
import sortSteps from 'helpers/sortSteps';
import { ByproductStepEdit, CarrierStepEdit, flattenSteps, FormStepEdit, MultiplierStepEdit, NestStepEdit, PrintStepEdit, SplitStepEdit, SubprocessesStepEdit } from 'container/Process/Edit';
import styled from 'styled-components'
import ExportStepEdit from 'container/Process/Step/Export';
import ImportStepEdit from 'container/Process/Step/Import';

function getStepVariablesBase({ branches, steps }) {
  const stepVariables = {}

  let before = false
  // eslint-disable-next-line
  for (const branch of branches) {
    const { stepVariables: subStepVariables, after } = getStepVariablesBase(branch)
    Object.assign(stepVariables, subStepVariables)
    if (after) {
      before = true
    }
  }

  // eslint-disable-next-line
  for (const step of steps) {
    let after = before
    if (step.type === 'split') {
      after = step.splitStep.newBatchVariableQuantity && !step.splitStep.newBatchVariableQuantityPredetermined
    }
    if (
      after &&
      (step.type === 'split' || step.type === 'form') &&
      step[`${step.type}Step`].form.fields.models.some((field) => field.type === 'quantity')
    ) {
      after = false
    }
    stepVariables[step.cid] = { before, after }
    before = after
  }

  return { stepVariables, after: before }
}

function getStepVariables(steps) {
  const { stepVariables } = getStepVariablesBase(steps)
  return stepVariables
}

const STEP_EDIT = {
  print: PrintStepEdit,
  form: FormStepEdit,
  multiplier: MultiplierStepEdit,
  subprocesses: SubprocessesStepEdit,
  split: SplitStepEdit,
  carrier: CarrierStepEdit,
  byproduct: ByproductStepEdit,
  export: ExportStepEdit,
  import: ImportStepEdit,
  nest: NestStepEdit
}

const DraftBanner = styled.span`
  color: rgba(34, 36, 38, 0.45);
  font-size: 1.1rem;
  padding: 0.25rem 0.375rem;
  font-weight: bold;
  background-color: #f8f0d0;
  border-radius: 0.375rem;
  align-self: flex-start;
  margin-left: 0.5rem;
  position: relative;
  top: -0.1rem;
`

const StepContainer = styled.div`
  display: flex;
  padding: ${() => isFeatureFlagEnabled('blue_skies') ? 50 : 25}px 0 25px;
  text-align: center;
  flex: 0 0 auto;
  background-color: #e0e0e2;
  position: relative;
  overflow-y: hidden;
`

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0.1rem 0.3rem rgba(0, 0, 0, 0.025);
  background-color: #f9fafa;
`

const InfoInnerContainer = styled.div`
  padding: 25px 25px 6rem;
`


const NewBatchGroup = styled(FormGroup)`
    border-color: #ff9040;
    > label:first-child {
        color: #ff9040;
    }
`

const SmallForm = styled(Form)`
  max-width: 1100px !important;
  margin: 0 auto;
`

const BatchSizeSwitch = styled.span`
  color: ${theme.accentColor};
  i.icon {
    margin: 0 !important;
  }
`

const BatchSize = styled.span`
  position: relative;
  &:hover > ${Tooltip} {
    ${openCss}
    font-weight: normal;
  }
`

const InfoHeader = styled.div`
  text-align: center;
  margin: 1.5rem 0 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  &:first-child {
    margin-top: 0;
  }
`

const StepContent = styled.div`
  padding: 25px;
`

const ModeToggle = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 1rem 0;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 1.5rem;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
  padding: 0 0.5rem;
  z-index: 100;
`

const ModeToggleOption = styled.div`
  width: 2.5rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  cursor: pointer;
  > i.icon {
    font-size: 1.25rem;
    color: ${({ active }) => (active ? theme.primaryColor : 'rgba(0, 0, 0, 0.5)')};
  }
`
const FORM_RELS = [
  'fields.articleType',
  'fields.dataSource',
  'fields.metafield',
  'fields.scanConstraints.leftMetafield',
  'fields.scanConstraints.rightMetafield',
]

export function getRequiredProcessVersionRelations() {
  return [
    'factory',
    'steps.workStation.productionLineVersion.workStations',
    'steps.workStation.productionLineVersion.productionLine.versions.workStations',
    'steps.capabilities',
    'steps.nextStep',
    'steps.sections.parts.textPart',
    'steps.sections.parts.tablePart',
    'steps.sections.parts.imagePart',
    'steps.sections.parts.metaPart.metafield',
    ...FORM_RELS.flatMap((rel) => [
      `steps.formStep.form.${rel}`,
      `steps.splitStep.form.${rel}`,
    ]),
    'steps.printStep',
    'steps.multiplierStep',
    'steps.subprocessesStep',
    'steps.carrierStep',
    'steps.byproductStep',
    'steps.exportStep.integration',
    'steps.importStep.integration',
    'steps.nestStep.nestType',
    'batchType.articleType',
    'batchType.targets',
    'updatedBy',
    'finalizedBy',
  ]
}

@observer
export default class ProcessVersionEdit extends Component {
    static propTypes = {
        header: PropTypes.node.isRequired,
        view: PropTypes.string.isRequired,
        baseUrl: PropTypes.string.isRequired,
        batchType: PropTypes.instanceOf(BatchType).isRequired,
        processVersion: PropTypes.instanceOf(ProcessVersion).isRequired,
        machineEnabled: PropTypes.bool,
    }

    constructor(...args) {
        super(...args)

        this.renderStepForm = this.renderStepForm.bind(this)
        this.selectStep = this.selectStep.bind(this)
        this.renderTop = this.renderTop.bind(this)
        this.renderSteps = this.renderSteps.bind(this)

        this.renderStepInfo = this.renderStepInfo.bind(this)
        this.renderInstructions = this.renderInstructions.bind(this)
    }

    @observable workingDays = null

    componentDidMount() {
        this.updateStepReaction = reaction(
            () => `${this.props.batchType.id},${this.props.processVersion.id}`,
            () => this.selectStep(null),
        )
        if (isFeatureFlagEnabled('blue_skies')) {
            const globalValue = new GlobalValue({ key: 'working_days' })
            globalValue.fetch().then(() => this.workingDays = globalValue.value)
        }
    }

    componentWillUnmount() {
        this.updateStepReaction()
    }

    render() {
        const { batchType, processVersion, view, header, baseUrl } = this.props

        if (['on_the_fly', 'on_the_fly_template'].includes(batchType.type)) {
            // On the fly settings screen
            return (
                <SmallContent>
                    <Header as="h1">{t('batchType.onTheFly.title')}</Header>
                    <p>{t('batchType.onTheFly.description')}</p>
                    <Form>
                        <TargetSerialNumberFormat allowAnything
                                                  allowArticleType={batchType.type.endsWith('_template')}
                                                  target={batchType}
                                                  name="onTheFlySerialNumberFormat"
                        />
                        <TargetRadioButtons info
                                            target={batchType}
                                            name="onTheFlyType"
                                            options={BatchType.ON_THE_FLY_TYPES.map((value) => ({
                                                value,
                                                text: t(`batchType.field.onTheFlyType.value.${value}`),
                                                tooltip: t(`batchType.field.onTheFlyType.tooltip.${value}`),
                                            }))}
                        />
                        {batchType.onTheFlyType === 'fixed' && (
                            <TargetNumberInput noLabel
                                               target={batchType}
                                               name="onTheFlySize"
                            />
                        )}
                        <TargetRadioButtons info
                                            target={batchType}
                                            name="onTheFlySource"
                                            options={BatchType.ON_THE_FLY_SOURCES.map((value) => ({
                                                value,
                                                text: t(`batchType.field.onTheFlySource.value.${value}`),
                                                tooltip: t(`batchType.field.onTheFlySource.tooltip.${value}`),
                                            }))}
                        />
                    </Form>
                </SmallContent>
            )
        } else {
            return (
                <Tabs
                    maxWidth="100%"
                    header={header}
                    tab={view}
                    baseUrl={baseUrl}
                    afterHeader={processVersion.draft && <DraftBanner>{t('process.edit.draft')}</DraftBanner>}
                    tabs={[
                        {
                            key: 'steps',
                            label: t('process.edit.tabs.steps'),
                            icon: 'ellipsis horizontal',
                            render: this.renderSteps,
                            noPadding: true,
                            bgColor: '#E0E0E2',
                        },
                        {
                            key: 'instructions',
                            label: t('process.edit.tabs.instructions'),
                            icon: 'info',
                            render: this.renderInstructions,
                            noPadding: true,
                            bgColor: '#F9FAFA',
                            'data-test-instruction-tab': true,
                        },
                    ]}
                />
            )
        }
    }

    // Steps

    @observable capabilityStore = new CapabilityStore()

    @computed get stepVariables() {
        return getStepVariables(this.steps)
    }


    calculateStepLeadingBatchDependencies(branches) {
        // First, make a list of all the last steps of the given branches
        const conflicts = branches.map((branch) => branch.steps[branch.steps.length - 1])
        let dependencies = {}
        // For each element, add all other elements as its dependencies
        for (let i = 0; i < conflicts.length; i++) {
            //Slice before and after the target element
            dependencies[conflicts[i].cid] = conflicts.slice(0, i).concat(conflicts.slice(i + 1))
        }
        //return found dependencies + call this function for each of the subbranches
        let other_branches = {}
        branches.forEach((branch) => {
            other_branches = { ...this.calculateStepLeadingBatchDependencies(branch.branches), ...other_branches }
        })
        return { ...other_branches, ...dependencies }
    }

    @computed get stepLeadingBatchDependencies() {
        return this.calculateStepLeadingBatchDependencies(this.steps.branches)
    }


    renderStepForm(step) {
        const { batchType, processVersion, machineEnabled } = this.props
        const StepDetails = STEP_EDIT[step.type]
        const batchSizes = this.stepBatchSizes[step.cid]
        const batchSizeBefore = batchSizes ? batchSizes.before : (Object.values(this.stepBatchSizes).find(({ syncTo }) => syncTo.includes(step)) || { after: null }).after
        const variable = this.stepVariables[step.cid]

        const errors = []

        if (step.type === 'split' && variable.before) {
            errors.push(t('articleType.edit.splitWithVariableQuantity'))
        }
        // eslint-disable-next-line
        for (const { code, message } of (step.actuallyUsefulErrors.type ?? [])) {
            if (['grow_at_start', 'multiplier_at_start'].includes(code)) {
                errors.push(message)
            }
        }

        let batchSizeFormContent = null
        if (batchSizeBefore === null) {
            batchSizeFormContent = t('process.edit.batchSize.value.productionRequest')
        } else if (batchSizeBefore === 1) {
            batchSizeFormContent = t('process.edit.batchSize.value.unit')
        } else {
            batchSizeFormContent = t('process.edit.batchSize.value.batch', { count: batchSizeBefore })
        }

        const isLed = processVersion.steps.models.some((prevStep) => prevStep.nextStep.getInternalId() === step.getInternalId() && prevStep.isLeadingBatch)

        return (
            <SmallForm data-test-step-form>
                {// if the current step is on the list of leading batch dependencies, it means it can become a leading batch
                    this.stepLeadingBatchDependencies[step.cid] && <Form.Group>
                        <TargetCheckbox info
                                        target={step}
                                        name="isLeadingBatch"
                                        onClick={() => {
                                            // HACK Because the actual change happens after the onClick, we check if the step
                                            // is currently NOT a leading batch, since it will become one.
                                            if (!step.isLeadingBatch) {
                                                this.stepLeadingBatchDependencies[step.cid].forEach((relatedStep) => {
                                                    relatedStep.isLeadingBatch = false
                                                })
                                            }
                                        }}
                        />
                    </Form.Group>}
                {errors.length > 0 && (
                    <Message visible error>{errors.map((error, i) => <div key={i}>{error}</div>)}</Message>
                )}
                {step.type !== 'multipier' && (
                    <TargetTextInput target={step} name="label" disabled={!processVersion.draft}/>
                )}
                <Form.Field>
                    <FormLabel>
                        {t('process.edit.batchSize.label')}
                        {isFeatureFlagEnabled('blue_skies') && (
                            <>
                                <RightDivider/>
                                <Popup
                                    trigger={<InfoIcon name="info circle"/>}
                                    content={t('splitStep.field.newBatchQuantity.info')}
                                />
                            </>
                        )}
                    </FormLabel>
                    {batchSizeFormContent}
                </Form.Field>
                {batchSizes && !isLed && (
                    <NewBatchGroup
                        label={t('process.edit.newBatch.label')}
                        info={t('process.edit.newBatch.info')}
                        bgColor="#FFF"
                    >
                        <TargetSerialNumberFormat
                            allowAnything={step.type === 'split' && step.splitStep.type === 'provided'}
                            allowArticleType={batchType.type.endsWith('_template')}
                            target={step}
                            name="newBatchSerialNumberFormat"
                            disabled={!processVersion.draft}
                            onChange={action((value) => {
                                step.setInput('newBatchSerialNumberFormat', value)
                                // eslint-disable-next-line
                                for (const step of batchSizes.syncTo) {
                                    step.setInput('newBatchSerialNumberFormat', value)
                                }
                            })}
                        />
                        {step.type === 'split' && (
                            <Form.Group>
                                <TargetNumberInput
                                    info={isFeatureFlagEnabled('blue_skies')}
                                    target={step.splitStep}
                                    name="newBatchQuantity"
                                    disabled={!processVersion.draft || step.splitStep.newBatchVariableUseOrderSize}
                                    width={8}
                                />
                                <TargetCheckbox info
                                                target={step.splitStep}
                                                name="newBatchVariableQuantity"
                                                disabled={!processVersion.draft}
                                                onChange={action((value) => {
                                                    step.splitStep.setInput('newBatchVariableQuantity', value);
                                                    if (!value && (step.splitStep.newBatchVariableQuantityPredetermined || step.splitStep.newBatchVariableUseOrderSize)) {
                                                        step.splitStep.setInput('newBatchVariableQuantityPredetermined', false);
                                                        step.splitStep.setInput('newBatchVariableUseOrderSize', false);
                                                    }
                                                })}
                                                width={4}
                                />
                                <TargetCheckbox info
                                                target={step.splitStep}
                                                name="newBatchVariableQuantityPredetermined"
                                                disabled={!processVersion.draft || !step.splitStep.newBatchVariableQuantity}
                                                width={4}
                                />
                                <TargetCheckbox info
                                                target={step.splitStep}
                                                name="newBatchVariableUseOrderSize"
                                                disabled={!processVersion.draft || !step.splitStep.newBatchVariableQuantity}
                                                width={4}
                                />
                            </Form.Group>
                        )}
                    </NewBatchGroup>
                )}
                {!['multiplier', 'subprocesses', 'grow'].includes(step.type) && (
                    <>
                        <WorkStationSelect search
                                           factory={processVersion.factory}
                                           target={step}
                                           name="workStation"
                                           defaultProductionLineVersion={this.defaultProductionLineVersion}
                                           disabled={!processVersion.draft}
                        />
                        <TargetSelect
                            multiple
                            remote
                            target={step}
                            name="capabilities"
                            store={this.capabilityStore}
                            toOption={(capability) => ({
                                value: capability.id,
                                text: capability.name,
                            })}
                            searchKey=".name:icontains"
                            disabled={!processVersion.draft}
                        />
                        <StepProcessTiming
                            step={step}
                            disabled={!processVersion.draft}
                        />
                    </>
                )}
                <StepDetails
                    step={step}
                    steps={this.steps}
                    disabled={!processVersion.draft}
                    machineEnabled={machineEnabled}
                    batchType={batchType}
                    processVersion={processVersion}
                    variable={variable}
                />
            </SmallForm>
        )
    }

    @computed get defaultProductionLineVersion() {
        const { processVersion } = this.props
        let productionLineVersion = null
        // eslint-disable-next-line
        for (const step of processVersion.steps.models) {
            if (!step.workStation.productionLineVersion.isNew) {
                if (productionLineVersion === null) {
                    productionLineVersion = step.workStation.productionLineVersion
                } else if (productionLineVersion.id !== step.workStation.productionLineVersion.id) {
                    return null
                }
            }
        }
        return productionLineVersion
    }

    @observable step = null

    selectStep(step) {
        if (this.step === step) {
            this.step = null
        } else {
            this.step = step
        }
    }

    @computed get stepBatchSizes() {
        return getStepBatchSizes(this.steps, null)
    }

    renderBatchSize(batchSize) {
        let content = null
        let tooltip = null
        if (batchSize === null) {
            tooltip = t('process.edit.batchSize.value.productionRequest')
            content = <Icon name="clipboard list"/>
        } else if (batchSize === 1) {
            tooltip = t('process.edit.batchSize.value.unit')
            content = <Icon name="cube"/>
        } else {
            tooltip = t('process.edit.batchSize.value.batch', { count: batchSize })
            content = <React.Fragment>
                <Icon name="cubes"/>
                <sub>{shortNumber(batchSize)}</sub>
            </React.Fragment>
        }

        return (
            <BatchSize>
                {content}
                <Tooltip>{tooltip}</Tooltip>
            </BatchSize>
        )
    }

    renderTop(step) {
        const batchSizes = this.stepBatchSizes[step.cid]

        if (batchSizes) {
            const { before, after } = batchSizes
            return (
                <BatchSizeSwitch>
                    {this.renderBatchSize(before)}
                    {after !== before && (
                        <>
                            <Icon name="chevron right"/>
                            {this.renderBatchSize(after)}
                        </>
                    )}
                </BatchSizeSwitch>
            )
        } else {
            return null
        }
    }

    @computed get hasErrors() {
        const { processVersion } = this.props
        return (
            processVersion.steps
                .filter((step) => (
                    // Split on variable batch
                    (step.type === 'split' && this.stepVariables[step.cid].before) ||
                    // Quantity field on non variable batch
                    (
                        ['split', 'form'].includes(step.type) &&
                        !(
                            step.type === 'split'
                                ? step.splitStep.newBatchVariableQuantity
                                : this.stepVariables[step.cid].before
                        ) &&
                        step[`${step.type}Step`].form.fields.models.some((field) => field.type === 'quantity')
                    )
                ))
                .map((step) => step.cid)
        )
    }

    @computed get addButtonErrors() {
        const { processVersion } = this.props
        const addButtonErrors = {}

        // eslint-disable-next-line
        for (const step of processVersion.steps.models) {
            const nextStep = processVersion.steps.find((s) => s.getInternalId() === step.nextStep.getInternalId())
            if (this.stepVariables[step.cid].after && !nextStep) {
                addButtonErrors[`${step.cid},null`] = [t('articleType.edit.endWithVariableQuantity')]
            }
        }

        return addButtonErrors
    }

    renderSteps() {
        const { batchType, processVersion } = this.props

        let excludeStepTypes = ['subprocesses', 'carrier']
        if (['transfer', 'receive_order'].includes(batchType.type)) {
            excludeStepTypes = ['multiplier', 'split', 'carrier']
        } else if (batchType.type === 'transfer') {
            excludeStepTypes = ['multiplier', 'split', 'subprocesses', 'carrier']
        } else if (batchType.type === 'pick_order') {
            excludeStepTypes = ['multiplier', 'split']
        }

        return (
            <React.Fragment>
                <StepContainer>
                    <Steps
                        renderTop={this.renderTop}
                        editable={processVersion.draft}
                        selected={this.step}
                        onSelect={this.selectStep}
                        steps={processVersion.steps}
                        color={theme.primaryColor}
                        batchType={batchType}
                        batchSize={batchType.quantity}
                        excludeStepTypes={excludeStepTypes}
                        hasErrors={this.hasErrors}
                        addButtonErrors={this.addButtonErrors}
                    />
                </StepContainer>
                <StepContent>
                    {this.step && processVersion.steps.models.includes(this.step) ? (
                        this.renderStepForm(this.step)
                    ) : (
                        <EmptyMessageContainer>{t('process.edit.noStepSelected')}</EmptyMessageContainer>
                    )}
                </StepContent>
            </React.Fragment>
        )
    }

    // Instructions

    @observable layoutEdit = false

    renderStepInfo(step, i) {
        const { processVersion } = this.props
        let isContentEmpty = step.sections.length === 0
        return (
            <React.Fragment key={step.cid}>
                {step.type !== 'multiplier' && (
                    <React.Fragment>
                        <InfoHeader>
                            <Icon name={stepIcon(step)}/>
                            {step.label}
                            {!isContentEmpty && (
                                <Button data-test-print-step-instructions-button={step.id}
                                    as="a"
                                    style={{ marginLeft: '10px', marginTop: '10px' }}
                                    href={`${step.api.baseUrl.substring(0, step.api.baseUrl.length - 1)}${step.url}print_instruction/`}
                                    target="_blank"
                                >
                                    Print
                                </Button>
                            )}
                        </InfoHeader>
                        <Sections
                            editable
                            sections={step.sections}
                            layoutEdit={this.layoutEdit && processVersion.draft}
                            onMoveUp={
                                i === 0
                                    ? undefined
                                    : action((section) => {
                                        step.sections.remove(section)
                                        this.flatSteps[i - 1].sections.models.push(section)
                                        this.flatSteps[i - 1].sections.markChanged()
                                    })
                            }
                            onMoveDown={
                                i === this.flatSteps.length - 1
                                    ? undefined
                                    : action((section) => {
                                        step.sections.remove(section)
                                        this.flatSteps[i + 1].sections.models.splice(0, 0, section)
                                        this.flatSteps[i + 1].sections.markChanged()
                                    })
                            }
                            disabled={!processVersion.draft}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    @computed get steps() {
        const { processVersion } = this.props
        return sortSteps(processVersion.steps.models)
    }

    @computed get flatSteps() {
        return flattenSteps(this.steps)
    }

    renderInstructions() {
        const { processVersion } = this.props;
        return (
            <InfoContainer id={'info-container'}>
                <Scrollbars>
                    <InfoInnerContainer>{this.flatSteps.map(this.renderStepInfo)}</InfoInnerContainer>
                </Scrollbars>
                {processVersion.draft && (
                    <ModeToggle>
                        <ModeToggleOption
                            data-test-instruction-edit-content-button
                            active={!this.layoutEdit}
                            onClick={() => (this.layoutEdit = false)}
                        >
                            <Icon name="pen"/>
                        </ModeToggleOption>
                        <ModeToggleOption
                            data-test-instruction-edit-layout-button
                            active={this.layoutEdit}
                            onClick={() => (this.layoutEdit = true)}
                        >
                            <Icon name="th-large"/>
                        </ModeToggleOption>
                    </ModeToggle>
                )}
            </InfoContainer>
        )
    }
}
