import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Modal } from 'semantic-ui-react'
import { humanReadable } from 'helpers/decimal'
import { showSaveNotification } from 'helpers/notification'
import { CancelButton, ApplyButton } from 'spider/semantic-ui/Button'
import RightDivider from 'spider/component/RightDivider'
import { t } from 'i18n'
import { ProductionRequest } from 'store/ProductionRequest'

interface ProductionRequestReduceQuantityModalProps {
    productionRequest: ProductionRequest,
    trigger: (props: object) => ReactNode,
}
@observer
export default class ProductionRequestReduceQuantityModal extends Component<ProductionRequestReduceQuantityModalProps> {
  @observable isOpen = false
  open = () => this.isOpen = true
  close = () => this.isOpen = false

  render() {
    const { productionRequest, trigger } = this.props;

    return (
      <Modal data-test-reduce-quantity-modal
        size="tiny"
        open={this.isOpen}
        onOpen={() => productionRequest.fetch()}
        onClose={this.close}
        trigger={trigger({ onClick: this.open })}
      >
        <Modal.Header>{t('productionRequest.reduceQuantityModal.header')}</Modal.Header>
        <Modal.Content>
          {t('productionRequest.reduceQuantityModal.content', { quantityReduced: humanReadable(productionRequest.quantity.sub(productionRequest.quantityNotYetStarted)) })}
        </Modal.Content>
        <Modal.Actions>
          <CancelButton onClick={this.close} />
          <RightDivider />
          <ApplyButton primary data-test-apply-button
            loading={productionRequest.isLoading}
            onClick={() =>
              productionRequest.reduceQuantityNotYetStarted()
                .then(showSaveNotification)
                .then(() => productionRequest.fetch())
                .then(() => this.close())
            }
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
