import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Popup, Table, Loader, Icon } from 'semantic-ui-react';
import { ProductionRequest } from 'store/ProductionRequest';
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion';
import { multiplyDecimals, humanReadable, round } from 'helpers/decimal'
import Decimal from 'decimal.js'
import { Link } from 'react-router-dom'

export function calculateRequiredQuantity(productionRequest, materialPlanItem) {
  // If requiredQuantity is set, calculate using quantityBatch or quantity and pr.requiredQuantity
  if (materialPlanItem.requiredQuantity !== null) {
    return materialPlanItem.requiredQuantity
  }

  return multiplyDecimals(materialPlanItem.quantityBatch ?? materialPlanItem.quantity, productionRequest.requiredQuantity)
}

/**
 * Do this seperately, to get the data parsed seperately, which makes the inital load 100x faster,
 */
@observer
class MaterialPlanPopUpContent extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest)
  }

  constructor(...args) {
    super(...args)

    this.renderMaterialPlanItem = this.renderMaterialPlanItem.bind(this)
  }

  renderRequiredQuantity(productionRequest, item) {
    const quantity = calculateRequiredQuantity(productionRequest, item)

    return (
      <Table.Cell collapsing data-test-required-quantity>
        {humanReadable(quantity)}
        {item.requiredQuantity === null && ` (${humanReadable(item.quantityBatch ?? item.quantity)} x ${productionRequest.requiredQuantity})`}
      </Table.Cell>
    )
  }

  renderMaterialPlanItem(item) {
    const { productionRequest } = this.props
    const rowProps = {}
    const quantity = calculateRequiredQuantity(productionRequest, item)

    // Sum of stock of other warehouses
    const warehouseStock = item.articleType.storageLocations.models
      .filter(sl => sl.warehouse.id !== productionRequest.productionOrder.warehouse.id)
      .reduce((sum, sl) => sum.add(sl.stock), Decimal(0))

    if (item.handledByErp) {
      // If the item is handled by the ERP, show a grayed out line
      return (
        <Table.Row disabled data-test-article-type-id={item.articleType.id}>
          <Table.Cell collapsing>{item.articleType.getLink()} {item.articleType.name}</Table.Cell>
          {this.renderRequiredQuantity(productionRequest, item)}
          <Table.Cell collapsing data-test-stock><i>{t('materialPlan.message.handledByErp')}</i></Table.Cell>
          <Table.Cell collapsing data-test-other-warehouse-stock>{humanReadable(warehouseStock)}</Table.Cell>
          <Table.Cell collapsing>{item.articleType.projectedStock}</Table.Cell>
        </Table.Row>
      )
    }

    const locations = item.articleType.storageLocations.filter(st => st.warehouse.id === productionRequest.productionOrder.warehouse.id)
    let stock = Decimal(0)

    if (locations.length > 0) {
      stock = locations.reduce((sum, { stock }) => sum.plus(stock), Decimal(0))
    }

    if (round(stock).lt(quantity)) {
      if (round(stock.add(warehouseStock)).gte(quantity)) {
        rowProps['warning'] = true
      } else {
        rowProps['negative'] = true
      }
    }

    if (round(stock).lte(0)) {
      return (
        <Table.Row {...rowProps} data-test-article-type-id={item.articleType.id}>
          <Table.Cell collapsing>{item.articleType.getLink()} {item.articleType.name}</Table.Cell>
          {this.renderRequiredQuantity(productionRequest, item)}
          <Table.Cell collapsing data-test-stock><i>{t('materialPlan.message.noStock')}</i></Table.Cell>
          <Table.Cell collapsing data-test-other-warehouse-stock>{humanReadable(warehouseStock)}</Table.Cell>
          <Table.Cell collapsing>{item.articleType.projectedStock}</Table.Cell>
        </Table.Row>
      )
    }

    return (
      <Table.Row {...rowProps} data-test-article-type-id={item.articleType.id}>
        <Table.Cell collapsing>{item.articleType.getLink()} {item.articleType.name}</Table.Cell>
        {this.renderRequiredQuantity(productionRequest, item)}
        <Table.Cell collapsing data-test-stock>{humanReadable(stock)}
          {/* With link that filters stock by this article_type and opens some storageLocation of this warehouse */}
          <Link data-test-stock-link-article={item.articleType.id}
            to={`/warehouse/stock/articletypewarehouse/${locations[0].storageLocation.id}?.warehouse_article_type_search=${item.articleType.code ? item.articleType.code : item.articleType.id}`}
          >
            <Icon name='eye' />
          </Link>
        </Table.Cell>
        <Table.Cell data-test-other-warehouse-stock collapsing>{humanReadable(warehouseStock)}</Table.Cell>
        <Table.Cell collapsing>{item.articleType.projectedStock}</Table.Cell>
      </Table.Row>
    )
  }

  @observable materialPlan = new BillOfMaterialVersion(null, {
    relations: [
      'items.articleType.storageLocations.storageLocation',
      'items.articleType.storageLocations.warehouse',
    ]
  });


  componentDidMount() {
    const { productionRequest } = this.props;
    this.materialPlan.id = productionRequest?.productionOrder?.materialPlan?.id

    // Only fetch if the production request has a material plan. Otherwise we get a crash
    if (this.materialPlan.id) {
      this.materialPlan.fetch()
    }
  }

  render() {
    if (this.materialPlan.isLoading) {
      return (<Loader data-test-loading={true} />);
    }

    const materials = this.materialPlan.items.filter(item => item.type === 'material' && !item.isByproduct).sort((a, b) => a.number - b.number)

    return (
      <div style={{ overflow: 'auto', maxHeight: 500 }} data-test-loading={false} >
        {materials.length === 0 ?
          t('materialPlan.noMaterials')
          : (
            <Table compact style={{ width: '500px' }}>
              <Table.Header>
                <Table.HeaderCell>{t('materialPlan.item')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.quantityRequired')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.stock')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.warehouseStock')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.projected')}</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {materials.map(this.renderMaterialPlanItem)}
              </Table.Body>
            </Table>
          )}
      </div>
    )
  }

}

/**
 * Popup which shows the materials fo the BOM list
 */
@observer
export default class MaterialPlanPopUp extends Component {
  static propTypes = {
    trigger: PropTypes.node,
    productionRequest: PropTypes.instanceOf(ProductionRequest)
  }


  render() {
    const { trigger, productionRequest } = this.props;

    return (
      <Popup flowing hoverable
        position='right center'
        data-test-material-plan-popup
        trigger={trigger}
        content={<MaterialPlanPopUpContent productionRequest={productionRequest} />}
      />
    )
  }
}
