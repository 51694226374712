import { observable, computed, action } from 'mobx'
import { Model, Store, Casts, subscribe } from 'store/Base'
import { ProcessVersion } from './ProcessVersion'
import { BatchStore } from './Batch'
import { InShipment } from './InShipment'
import { InShipmentLine } from './InShipmentLine'
import { OutShipment } from './OutShipment'
import { OutShipmentLine } from './OutShipmentLine'
import { ArticleType } from './ArticleType'
import { Step } from './Step'
import { ProductionOrder } from './ProductionOrder'
import { WarehouseTransfer, WarehouseTransferStore } from './WarehouseTransfer'
import { WarehouseTransferLine } from './WarehouseTransferLine'
import { Project } from './Project'
import { MaterialIssueStore } from './MaterialIssue'
import { MetavalueStore } from './Metavalue'
import { StockCount } from './StockCount'
import { ProductionRequestLinkStore } from './ProductionRequestLink'
import { Link } from 'react-router-dom'
import Decimal from 'decimal.js';
import { NestRequest } from './Workstation/NestRequest'
import { Nest } from './Nest'
import { LoadCarrier } from './LoadCarrier'

export const MARKED_COLORS = ['red', 'blue', 'yellow', 'violet', 'grey', 'black']


function getWorkMinutes({ branches, steps }) {
  let time = new Decimal(0)

  // eslint-disable-next-line
  for (const branch of branches) {
    time.plus(getWorkMinutes(branch))
  }

  // eslint-disable-next-line
  for (const step of steps) {
    if (step.type === 'multiplier') {
      time *= step.multiplierStep.multiplier
    } else {
      time = time.plus(step.workMinutes ?? new Decimal(0))
    }
  }
  return time
}


export class ProductionRequest extends Model {
  static backendResourceName = 'production_request'
  static idPrefix = 'WO'
  static idColor = 'teal'

  getUrl() {
    return `/operations/production-request/overview?.id=${this.id}`
  }

  getUrlLine() {
    return `/planning/production-request/planner?.id=${this.id}`
  }

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  getLinkLine(props = {}) {
    const to = this.getUrlLine()

    if (to !== '') {
      props = {
        as: Link,
        to,
        style: { textDecoration: 'none' },
        ...props,
      }
    }
    return this.getLabel(props)
  }

  getTarget() {
    return '_blank'
  }




  constructor(...args) {
    super(...args)
    this.onPerformance = this.onPerformance.bind(this)
    this.onPerformanceReset = this.onPerformanceReset.bind(this)
    this.onSuperrequestAtSubprocessesChange = this.onSuperrequestAtSubprocessesChange.bind(this)
    this.onSubrequestsFinishedChange = this.onSubrequestsFinishedChange.bind(this)
  }

  @observable id = null
  @observable quantity = Decimal(0)
  @observable quantityNotYetStarted = Decimal(0)
  @observable startAt = null
  @observable endAt = null
  @observable startDate = null
  @observable period = null
  @observable flagged = false
  @observable groupLeader = null
  @observable groupDescription = null
  @observable endDate = null
  @observable totalRunTime = 0
  @observable materialsAvailability = 'red'
  @observable drawingImage = null
  @observable markedColors = []
  @observable manualFinishedReason = ''


  // Annotations

  @observable erpPlannedEndDate = null;
  @observable finished = false
  @observable quantityDone = Decimal(0)
  @observable startedAt = null
  @observable endedAt = null
  @observable duration = 0
  @observable progress = 0
  @observable workMinutesTotal = 0
  @observable workMinutesDone = 0
  @observable subrequestsFinished = false
  @observable superrequestAtSubprocesses = false
  @observable requestStatus = null
  @observable released = false
  @observable tracyStatus = null
  @observable currentNestingStepType = ''
  @observable productionLineNames = []
  @observable lineNumber = -1
  @observable supplier = ''
  @observable delivery = ''


  // Trafficlights
  @observable inboundTrafficLight;

  @observable outboundTrafficLight;
  @observable materialPlanTrafficLight;
  @observable capacityTrafficLight;

  @computed
  get _labelContent() {
    return this.number
  }

  @computed
  get isEditable() {
    return ['todo', null].includes(this.requestStatus)
  }

  @computed get totalProcessTime() {
    return this.totalRunTime + this.processVersion.totalSetupMinutes + this.processVersion.totalDelayMinutes
  }

  // BOM items required quantity calculation:
  // [T39105] some boms have a default quantity, if not multiply by 1.
  @computed get requiredQuantity() {
    return (this.quantity ?? 0) / (this?.productionOrder?.billOfMaterialVersion?.defaultBatchQuantity || 1)
  }

  getWorkMinutesExpectedDone(processVersion) {
    return this.batches.models
      .flatMap((target) => target.performances.models)
      .map((performance) => processVersion.steps.get(performance.step.id).workMinutes)
      .reduce((a, b) => a + b, 0)
  }

  getWorkMinutesActualDone() {
    return this.batches.models
      .flatMap((target) => target.performances.models)
      .map((performance) => performance.createdAt.diff(performance.startedAt, 'm', true))
      .reduce((a, b) => a + b, 0)
  }

  getWorkMinutesTotal(steps) {
    return getWorkMinutes(steps) * this.quantity
  }

  @computed get done() {
    return this.quantityDone >= this.quantity
  }

  relations() {
    return {
      processVersion: ProcessVersion,
      batches: BatchStore,
      productionOrder: ProductionOrder,
      inShipment: InShipment,
      inShipmentLine: InShipmentLine,
      outShipment: OutShipment,
      outShipmentLine: OutShipmentLine,
      warehouseTransfer: WarehouseTransfer,
      warehouseTransferLine: WarehouseTransferLine,
      warehouseTransfers: WarehouseTransferStore,
      project: Project,
      materialIssues: MaterialIssueStore,
      stockCount: StockCount,
      articleType: ArticleType,
      superrequest: ProductionRequest,
      metavalues: MetavalueStore,
      superProductionRequestLinks: ProductionRequestLinkStore,
      nestRequest: NestRequest,
      nests: Nest,
      flatMetavalues: MetavalueStore,
    }
  }



  casts() {
    return {
      quantity: Casts.decimal,
      quantityNotYetStarted: Casts.decimal,
      quantityDone: Casts.decimal,
      startAt: Casts.tzDatetime,
      startDate: Casts.date,
      endDate: Casts.date,
      startedAt: Casts.datetime,
      endAt: Casts.tzDatetime,
      endedAt: Casts.datetime,
      erpPlannedEndDate: Casts.date
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.quantity_done
    delete res.started_at
    delete res.ended_at
    delete res.duration
    delete res.progress
    delete res.finished
    delete res.work_minutes_total
    delete res.work_minutes_done
    delete res.subrequests_finished
    delete res.superrequest_at_subprocesses
    delete res.inbound_traffic_light;
    delete res.outbound_traffic_light;
    delete res.material_plan_traffic_light;
    delete res.capacity_traffic_light;
    delete res.released;
    delete res.production_line_names;
    delete res.line_number
    delete res.erp_planned_end_date
    delete res.supplier
    delete res.delivery
    return res
  }

  toBackendAll({ nestedRelations: { metavalues, ...nestedRelations } = {}, ...options } = {}) {
    const { data: [data], relations } = super.toBackendAll({ nestedRelations, ...options })

    if (metavalues !== undefined) {
      delete data.metavalues

      // eslint-disable-next-line
      for (const metavalue of this.metavalues.models) {
        const { data: [subdata], relations: subrelations } = metavalue.toBackendAll({ nestedRelations: metavalues })
        delete subdata.metafield
        data[`metafield(${metavalue.metafield.id})`] = subdata

        // eslint-disable-next-line
        for (const [rel, reldata] of Object.entries(subrelations)) {
          if (relations[rel] === undefined) {
            relations[rel] = []
          }
          relations[rel].push(...reldata)
        }
      }
    }

    return { data: [data], relations }
  }


  subscribeToPerformances(options) {
    const performanceSubscription = subscribe(
      { type: 'performance', production_request: this.id },
      (performance) => this.onPerformance(performance, options),
    )
    const performanceResetSubscription = subscribe(
      { type: 'performance_reset', production_request: this.id },
      (performance) => this.onPerformanceReset(performance, options),
    )
    const superrequestAtSubprocessesChangeSubscription = subscribe(
      { type: 'superrequest_at_subprocesses_change', production_request: this.id },
      (change) => this.onSuperrequestAtSubprocessesChange(change, options),
    )
    const subrequestsFinishedChangeSubscription = subscribe(
      { type: 'subrequests_finished_change', production_request: this.id },
      (change) => this.onSubrequestsFinishedChange(change, options),
    )

    return {
      performanceSubscription,
      performanceResetSubscription,
      superrequestAtSubprocessesChangeSubscription,
      subrequestsFinishedChangeSubscription,
      unsubscribe: () => {
        performanceSubscription.unsubscribe()
        performanceResetSubscription.unsubscribe()
        superrequestAtSubprocessesChangeSubscription.unsubscribe()
        subrequestsFinishedChangeSubscription.unsubscribe()
      },
    }
  }

  @action onPerformance({ data: { step, batch, operator, performed_at, details = [], production_request } }, { removeFinalized = false } = {}) {
    const batchModel =
      this.batches.get(batch.id) ||
      this.batches.add({
        id: batch.id,
        serialNumber: batch.serial_number,
      })

    this.quantityDone = production_request.quantity_done
    this.workMinutesDone = production_request.work_minutes_done

    if (removeFinalized && batch.finalized_at !== null) {
      this.batches.remove(batchModel);
      return;
    }

    batchModel.quantity = Decimal(batch.quantity)
    batchModel.variableQuantity = batch.variable_quantity
    batchModel.quantityRemaining = Decimal(batch.quantity_remaining)
    batchModel.finished = batch.finished
    batchModel.lastPerformance = Casts.datetime.parse('lastPerformance', performed_at)

    // eslint-disable-next-line
    for (const usage of batch.usages) {
      const usageModel = batchModel.batchUsings.add({ quantity: Decimal(usage.quantity) })
      usageModel.usedBatch = this.batches.get(usage.batch)
      usageModel.usedBatch.quantityRemaining = usageModel.usedBatch.quantityRemaining.sub(usage.quantity)
    }

    if (batchModel.performances !== undefined) {
      const performance = batchModel.performances.add({
        step: {
          id: step.id,
        },
        operator: {
          id: operator.id,
          firstName: operator.first_name,
          lastName: operator.last_name,
        },
        createdAt: performed_at,
        details: details.map((detail) => ({
          id: detail.id,
          components: detail.components.map((batch) => ({
            id: batch.id,
            serialNumber: batch.serialNumber,
            batchUsings: batch.batch_usings.map((usage) => ({
              id: usage.id,
              quantity: usage.quantity,
              usedBatch: {
                id: usage.used_batch.id,
                serialNumber: usage.used_batch.serial_number,
                quantity: usage.used_batch.quantity,
              },
            })),
          })),
        })),
      })

      if (performance.details.length > 0) {
        performance.details.params['.id:in'] = performance.details.map(({ id }) => id).join(',')
        performance.details.fetch()
      }
    }

    batchModel.setInput('lastStep', new Step({ id: step.id }))
    batchModel.setInput('finished', batch.finished)
    batchModel.setInput('finalizedAt', Casts.datetime.parse('finalizedAt', batch.finalized_at))
    if (batch.load_carrier !== undefined) {
      batchModel.setInput('loadCarrier', new LoadCarrier({ id: batch.load_carrier.id, serialNumber: batch.load_carrier.serial_number }))
    }
  }

  @action onPerformanceReset({ data: { batch, scrapped } }) {
    /** requires `data` like:
     *    batch: {
     *      id: some_batch_id,
     *      last_step: some_step_id
     *    },
     *    scrapped: [{
     *      id: some_batch_id,
     *      scrap_reason: some_string
     *    }, ...]
     */
    const finished = scrapped.length > 0 && this.batches.filter(({ finished }) => finished)

    // eslint-disable-next-line
    for (const { id, scrap_reason } of scrapped) {
      const batch = this.batches.get(id)
      if (batch.scrapReason === null) {
        // eslint-disable-next-line
        for (const usage of batch.batchUsings.models) {
          const usedBatch = this.batches.get(usage.usedBatch.id)
          usedBatch.quantityRemaining = Decimal(usedBatch.quantityRemaining).add(usage.quantity)
        }
      }
      batch.scrapReason = scrap_reason

      for (let i = 0; i < finished.length; i++) {
        if (finished[i].batchUsings.models.some((usage) => usage.usedBatch.id === id)) {
          finished[i].finished = false
          finished.splice(i, 1)
          i--
        }
      }
    }

    const targetBatch = this.batches.get(batch.id)
    targetBatch.setInput('lastStep', new Step({ id: batch.last_step }))
    targetBatch.setInput('finished', false)
    targetBatch.setInput('finalizedAt', null)
  }

  @action onSubrequestsFinishedChange({ data: { subrequests_finished } }) {
    this.subrequestsFinished = subrequests_finished
  }

  @action onSuperrequestAtSubprocessesChange({ data: { superrequest_at_subprocesses } }) {
    this.superrequestAtSubprocesses = superrequest_at_subprocesses
  }

  async fastForward(days) {
    await this.api.post('production_request/fast_forward/', { days })
    return await this.fetch()
  }

  async toggleFlagged() {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}toggle_flagged/`))
      this.flagged = !this.flagged
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }

  async moveToPeriod(period) {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}move_to_period/`, { period }))
      this.period = period
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }

  reduceQuantityNotYetStarted() {
    return this.wrapPendingRequestCount(this.api.post(this.url + 'reduce_quantity_not_yet_started/'))
  }

  async toggleFinishedStatus() {
    try {
      await this.wrapPendingRequestCount(
        this.api.post(`${this.url}update_finished_status/`, { manual_finished_reason: this.manualFinishedReason })
      )
    } catch (err) {
      const valErrors = this.api.parseBackendValidationErrors(err.response)

      if (valErrors) {
        this.parseValidationErrors(valErrors);
      }
      throw err;
    }
  }

  @computed get isReleased() {
    return this.released
  }
}

function withToTree(withs) {
  const tree = {}

  // eslint-disable-next-line
  for (const with_ of withs.split(',')) {
    let node = tree
    // eslint-disable-next-line
    for (const rel of with_.split('.')) {
      if (node[rel] === undefined) {
        node[rel] = {}
      }
      node = node[rel]
    }
  }
  return formatWithTree(tree)
}

function formatWithTree(tree) {
  const subtrees = []

  // eslint-disable-next-line
  for (let [rel, subtree] of Object.entries(tree)) {
    let entries = Object.entries(subtree)

    while (entries.length === 1) {
      rel = `${rel}.${entries[0][0]}`
      subtree = entries[0][1]
      entries = Object.entries(subtree)
    }

    if (entries.length === 0) {
      subtrees.push(rel)
    } else {
      subtrees.push(`${rel}(${formatWithTree(subtree)})`)
    }
  }

  return subtrees.join(',')
}

class ProductionRequestMetaCounts {

  // With the current filters, total amount of open PRs (= not yet released in tracy)
  @observable countOpen = 0;

  // With the current filters, the total amount of released shop orders (in tracy, released to shop floor)
  @observable countReleased = 0;

  // With current filters, total amount of flagged PRs
  @observable countFlagged = 0;


  // With the current filters, total amount of prs with inbound issues (=prs too late)
  @observable countInboundIssues = 0;

  // With the current filters, total amount of prs with outbound issues (expected end time too late)
  @observable countOutboundIssues = 0

  // with the current filters, total amount of PRs with stock issues
  @observable countMaterialPlanIssues = 0;


  // with the current filters, total amount of PRs with capacity
  @observable countCapacityIssues = 0;


  /**
   * Update the counts based upon the resulting meta data from the store
   */
  @action
  updateFromMeta = (meta) => {
    this.countOpen = meta.count_open;
    this.countReleased = meta.count_released
    this.countFlagged = meta.count_flagged;
    this.countInboundIssues = meta.count_inbound_issues;
    this.countOutboundIssues = meta.count_outbound_issues;
    this.countMaterialPlanIssues = meta.count_material_plan_issues;
    this.countCapacityIssues = meta.count_capacity_issues;
  }
}

export class ProductionRequestStore extends Store {
  Model = ProductionRequest
  static backendResourceName = 'production_request'

  /**
   * Keep track of the meta values of the production request store
   */
  metaCounts = new ProductionRequestMetaCounts()

  constructor(...args) {
    super(...args)
    this.onPerformance = this.onPerformance.bind(this)
    this.onPerformanceReset = this.onPerformanceReset.bind(this)
    this.onSuperrequestAtSubprocessesChange = this.onSuperrequestAtSubprocessesChange.bind(this)
    this.onSubrequestsFinishedChange = this.onSubrequestsFinishedChange.bind(this)
  }

  subscribeToPerformances(production_request = '*', options) {
    const performanceSubscription = subscribe(
      { type: 'performance', production_request },
      (performance) => this.onPerformance(performance, options),
    )
    const performanceResetSubscription = subscribe(
      { type: 'performance_reset', production_request },
      (performance) => this.onPerformanceReset(performance, options),
    )
    const superrequestAtSubprocessesChangeSubscription = subscribe(
      { type: 'superrequest_at_subprocesses_change', production_request },
      (change) => this.onSuperrequestAtSubprocessesChange(change, options),
    )
    const subrequestsFinishedChangeSubscription = subscribe(
      { type: 'subrequests_finished_change', production_request },
      (change) => this.onSubrequestsFinishedChange(change, options),
    )

    return {
      performanceSubscription,
      performanceResetSubscription,
      superrequestAtSubprocessesChangeSubscription,
      subrequestsFinishedChangeSubscription,
      unsubscribe: () => {
        performanceSubscription.unsubscribe()
        performanceResetSubscription.unsubscribe()
        superrequestAtSubprocessesChangeSubscription.unsubscribe()
        subrequestsFinishedChangeSubscription.unsubscribe()
      },
    }
  }

  onPerformance(performance, options) {
    const productionRequest = this.get(performance.data.batch.production_request)
    if (productionRequest) {
      productionRequest.onPerformance(performance, options)
    }
  }

  onPerformanceReset(performance, options) {
    const productionRequest = this.get(performance.data.batch.production_request)
    if (productionRequest) {
      productionRequest.onPerformanceReset(performance, options)
    }
  }

  onSubrequestsFinishedChange(change, options) {
    const productionRequest = this.get(change.data.production_request)
    if (productionRequest) {
      productionRequest.onSubrequestsFinishedChange(change, options)
    }
  }

  onSuperrequestAtSubprocessesChange(change, options) {
    const productionRequest = this.get(change.data.production_request)
    if (productionRequest) {
      productionRequest.onSuperrequestAtSubprocessesChange(change, options)
    }
  }

  buildFetchData(...args) {
    const data = super.buildFetchData(...args)
    if (data.with) {
      data.with = withToTree(data.with)
    }
    return data
  }

  getTypeCounts() {
    const params = this.buildFetchData({})

    delete params['.process_version.batch_type.type:in']
    delete params.with
    delete params.where
    delete params.limit
    delete params.offset

    return this.api.get(`${this.url()}type_counts/`, params)
  }

  updatePlannedAtValue() {
    this.api.post('production_request/update_planned_at/', { new_scope: window.viewStore.progressScope })
  }


  @action
  fetch(options = {}) {
    const promise = super.fetch(options);

    promise.then(res => this.metaCounts.updateFromMeta(res['meta']))

    return promise
  }

  @computed get isAllReleased() {
    return this.models.every((pr) => pr.isReleased)
  }

  @computed get isAllNotReleased() {
    return this.models.every((pr) => !pr.isReleased)
  }
}
