import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Batch } from 'store/Batch'
import { Step } from 'store/Step'
import { ProductionRequest } from 'store/ProductionRequest'
import { observable } from 'mobx'
import { Popup, Table } from 'semantic-ui-react'
import { humanReadable } from 'helpers/decimal'
import BatchReworkModal from 'component/BatchReworkModal'
import { IconButton } from 'spider/semantic-ui/Button'
import StepCount from 'component/StepCount'
import { t } from 'i18n'
import Decimal from 'decimal.js'
import { StyledTableRow } from 'spider/semantic-ui/Admin/Overview';
import styled from 'styled-components';

const ColoredTableRow = styled(StyledTableRow)`
    color: ${props =>  props.color};
`;

interface StepCountWithBatchesPopupProps {
    batches: Batch[];
    step: Step;
    productionRequest: ProductionRequest;
    articleCount: Decimal;
}

/**
 * Show the step count with a popup showing the batch details in that step.
 *
 * The popup is shown on hover, but a rework modal is shown when clicking on the rework button. Default semantic-ui
 * would hide the popup when moving out of component, which would then close the rework modal. Here we customize the
 * popup open / close handler so it works together with showing a modal.
 */
@observer
export default class StepCountWithBatchesPopup extends Component<StepCountWithBatchesPopupProps> {
    @observable isOpen = false
    @observable isModalShown = false

    /**
     * When the popup is supposed to close, we add a small delay so the user has time to move the mouse from the count
     * to the popup.
     */
    closeTimeout: ReturnType<typeof setTimeout> | undefined

    open = (e) => {
        this.preventPopupFromClosing()
        e?.stopPropagation?.()
        this.isOpen = true
    }
    close = () => {
        this.preventPopupFromClosing()

        // When the rework modal is shown, don't allow closing the popup. If the popup is closed, the modal will also
        // be closed because the modal is `rendered` in the popup.
        if (!this.isModalShown) {
            this.closeTimeout = setTimeout(() => this.isOpen = false, 1000)
        }
    }

    /**
     * Prevent popup from closing by clearing the timeout.
     */
    preventPopupFromClosing = () => clearTimeout(this.closeTimeout)

    renderBatchTable = () => {
        const { batches } = this.props



        return (
            <Table compact style={{ minWidth: '150px' }}>
                <Table.Header>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>{t('batch.field.serialNumber.label')}</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Header>
                <Table.Body>
                    {batches.map((batch: Batch) =>{
                        let color = 'none'
                        if (batch.scrapReason){
                            color = 'grey'
                        }
                        else if (batch.deleted){
                            color = 'red'
                        }

                        return(
                        <ColoredTableRow data-test-popup-batch-table-row={batch.id} color={color}>
                            <Table.Cell data-test-batch-quantity-remaining>{humanReadable(batch.quantityRemaining)}</Table.Cell>
                            <Table.Cell data-test-batch-serial-number>{batch.serialNumber}</Table.Cell>
                            <Table.Cell data-test-batch-buttons>
                                {!batch.scrapReason && <BatchReworkModal
                                    target={batch}
                                    onClose={() => {
                                        this.isModalShown = false
                                        this.close()
                                    }}
                                    trigger={props => (
                                        <IconButton
                                            {...props}
                                            onClick={(...args) => {
                                                this.isModalShown = true
                                                props.onClick(...args)
                                            }}
                                            data-test-rework-batch={batch.serialNumber}
                                            name="redo"
                                            label={t('batch.button.rework.label')}
                                        />
                                    )}
                                />}
                            </Table.Cell>
                        </ColoredTableRow>
                    )})}
                </Table.Body>
            </Table>
        )
    }

    render() {
        const { batches, step, productionRequest, articleCount } = this.props;

        return (
            <Popup data-test-step-batch-count-modal
                // This onClick handler prevents closing the popup when the BatchReworkModal is shown, and the user
                // clicks inside the modal.
                onClick={e => e?.stopPropagation?.()}
                on="hover"
                open={this.isOpen}
                onOpen={this.open}
                onClose={this.close}
                trigger={(
                    // This triggers the popup to be shown on mouse hover. Moving the mouse out will start the timer to
                    // close the popup.
                    <StepCount data-test-production-request-step-count data-test-production-perform-count
                        data-test-step-id={step.id}
                        data-test-production-request-id={productionRequest.id}
                        onClick={this.open}
                    >
                        {humanReadable(articleCount)}
                    </StepCount>
                )}
                content={(
                    <div data-test-popup-batches
                         // When the user moves the mouse into the actual content of the popup, we prevent the popup
                         // from closing...
                         onMouseEnter={() => this.preventPopupFromClosing()}
                         // ... but when the user moves the mouse out, we start the process of closing the popup.
                         onMouseLeave={() => this.close()}
                         style={{ overflow: 'auto', maxHeight: 500 }}
                    >
                        {batches.length <= 0
                            ? t('batch.batchInfoModal.noBatches')
                            : this.renderBatchTable()
                        }
                    </div>
                )}
            />
        )
    }
}

