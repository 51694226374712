import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RightDivider from 'spider/component/RightDivider';
import { Modal, Icon } from 'semantic-ui-react';
import { SaveButton } from 'spider/semantic-ui/Button';
import { Detail } from 'store/Detail';
import { TargetDatePicker, TargetTextInput } from 'spider/semantic-ui/Target'
import { DateTime } from 'luxon';
import { showSaveNotification } from 'helpers/notification'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const EditIconButton = styled(Icon)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5em !important;
  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
`


@observer
export default class DetailEditModal extends Component {
    static propTypes = {
        detail: PropTypes.instanceOf(Detail).isRequired,
        onFetchHistory: PropTypes.func.isRequired,
    };

    @observable isOpen = false
    @observable newValue = null

    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    async onSaveDetail() {
        const { detail } = this.props;

        await detail.save({ onlyChanges: true, data: { value: this.newValue } })
    }

    renderEditDetail() {
        const { detail } = this.props;

        switch (detail.field.type) {
            case 'text':
                return (
                    <TargetTextInput data-test-text-field autoFocus
                        onChange={action((value) => {
                            this.newValue = value
                            detail.value = this.newValue
                        })}
                        value={detail.value || ''}
                    />
                )
            case 'best_before_date':
                return (
                    <TargetDatePicker data-test-best-before-date-picker
                        value={DateTime.fromISO(detail.value)}
                        onChange={action((bestBeforeDate) => {
                            this.newValue = DateTime.fromISO(bestBeforeDate).toFormat('yyyy-LL-dd')
                            detail.value = this.newValue
                        })}
                    />
                )
            default:
                throw new Error('Type not allowed!')
        }
    }

    render() {
        const { detail, onFetchHistory } = this.props;

        return (
            <Modal
                closeIcon
                trigger={<EditIconButton data-test-edit-detail-button name="edit" />}
                open={this.isOpen}
                onOpen={this.open}
                onClose={this.close}
            >
                <Modal.Header>
                    {`Edit field: ${detail.field.label} (type ${detail.field.type})`}
                </Modal.Header>
                <Modal.Content>
                    <Container>
                        {this.renderEditDetail()}
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <RightDivider />
                    <SaveButton data-test-save-detail
                        disabled={this.newValue === null || this.newValue.length === 0}
                        onClick={async () => {
                            await this.onSaveDetail()
                            showSaveNotification()
                            await onFetchHistory()
                            this.close()
                        }}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}