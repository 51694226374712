import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Button, Table, TableRow, TableCell } from 'semantic-ui-react'
import LimitedDecimalInput from '../Target/LimitedDecimalInput'
import Decimal from 'decimal.js'
import styled from 'styled-components'
import { COLORS } from '../../spider/semantic-ui/colors'

const TableCellLimitContainer = styled(TableCell)`
  padding: 0px !important;
  position: relative;
  border-top: none !important;
  // Class gets added if all tasks are completed for some row, will turn input "finished green"
  .alltaskcomplete { 
    input {
      background: ${COLORS.teal} !important;
    } 
  }
  .partiallycomplete { 
    input {
      background: ${COLORS.yellow} !important;
    } 
  }
`

const NoPaddingTableCell = styled(TableCell)`
  padding: 0px !important;
  position: relative;
  border-top: none !important;
`

const PlusMinusButton = styled(Button)`
  height: 2.7em;
  width: ${({ width }) => width}em;
  margin-top: 0.25em !important;
  margin-left: 0.25em !important;
  margin-right: 0.25em !important;
`

const SmallLimitedDecimalInput = styled(LimitedDecimalInput)`
  width: ${({ inputWidth }) => inputWidth}em !important;
`

/**
 * an input with a + and a - to increase/decrease the formfield inbetween.
 *
 * Looks like: [-] [ 123 ] [+]
 *
 * Changes color:
 *  - if > capMin  ==> 'yellow'
 *  - if >= capMax ==> 'teal'
 */
@observer
export default class TargetDecimalPlusMinusInput extends Component {
    static propTypes = {
        capMin: PropTypes.number.isRequired,
        capMax: PropTypes.number.isRequired,
        setTarget: PropTypes.func.isRequired,
        value: PropTypes.instanceOf(Decimal).isRequired,
        id: PropTypes.number.isRequired,
        target: PropTypes.object.isRequired,
        // step size for incrementing/decrementing
        increment: PropTypes.number,
        decrement: PropTypes.number,
        // only use max/min for color highlighting
        disableMax: PropTypes.bool,
        disableMin: PropTypes.bool,
        // do not use color changing
        disableColors: PropTypes.bool,
        // width of buttons/input in em
        buttonWidth: PropTypes.number,
        inputWidth: PropTypes.number,
    }

    static defaultProps = {
        increment: Decimal(1),
        decrement: Decimal(1),
        buttonWidth: 4,
        inputWidth: 6,
        disableMax: false,
        disableMin: false,
        disableColors: false,
    }

    /**
     * @param {number} value
     * @returns Whether value has decimal points
     *
     * e.g. hasDecimals(1.11) ==> true
     *      hasDecimals(12) ==> false
     */
    hasDecimals(value) {
        return !value.equals(Math.floor(value))
    }

    /**
     * Increment the target by increment, or cap at capmax, or round to integer
     * @param {*} target
     */
    addTarget(target) {
        const { capMax, value, setTarget, increment, disableMax } = this.props

        if (!disableMax && Decimal(value).add(increment).gte(Decimal(capMax))) {
            // v + 1 >= max             ==>  set to capmax
            setTarget(target, Decimal(capMax))
        } else if (this.hasDecimals(value)) {
            // value is decimal         ==> set to ceiled
            setTarget(target, Math.ceil(value))
        } else {
            // v + 1 < max and integer  ==> v += 1
            setTarget(target, value.add(increment))
        }
    }

    /**
     * Decrement the target by decrement, or cap at capMin, or round to integer
     * @param {*} target
     */
    subTarget(target) {
        const { capMin, value, setTarget, decrement, disableMin } = this.props

        if (!disableMin && Decimal(value).sub(decrement).lte(Decimal(capMin))) {
            // v - 1 <= min             ==>  set to capmin
            setTarget(target, Decimal(capMin))
        } else if (this.hasDecimals(value)) {
            // value is decimal         ==> set to floored
            setTarget(target, Math.floor(value))
        } else {
            // v - 1 > min and integer  ==> v -= 1
            setTarget(target, value.sub(decrement))
        }
    }

    getFieldStatus() {
        const { capMin, capMax, value, disableColors } = this.props
        // Sets the color of the field according to how much of the task is finished
        if (disableColors) {
            return undefined
        } else if (value.gte(capMax)){
            // All are finished
            return 'alltaskcomplete'
        } else if (value.gt(capMin)){
            // Some but not all are finished
            return 'partiallycomplete'
        }
        return undefined
    }

    render() {
        const { setTarget, id, target, buttonWidth, inputWidth, ...props } = this.props

        return <Table basic='very'>
            <TableRow>
            <NoPaddingTableCell collapsing>
            <PlusMinusButton
                width={buttonWidth}
                data-test-done-quantity-minus={id}
                icon="minus"
                onClick={() => this.subTarget(target)}
            />
            </NoPaddingTableCell>
            <TableCellLimitContainer>
                <SmallLimitedDecimalInput {...props}
                    inputWidth={inputWidth}
                    className={this.getFieldStatus()}
                    data-test-done-quantity-input={id}
                    onChange={(val) => {
                        setTarget(target, val)
                    }}
                />
            </TableCellLimitContainer>
            <NoPaddingTableCell collapsing>
            <PlusMinusButton
                width={buttonWidth}
                data-test-done-quantity-plus={id}
                icon="plus"
                onClick={() => this.addTarget(target)}
            />
            </NoPaddingTableCell>
            </TableRow>
        </Table>
    }

}
