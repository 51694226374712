import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import { TargetNumberInput } from 'spider/semantic-ui/Target'
import Decimal from 'decimal.js'


/**
 * When a value is inputed, and it is outside the range min..max (inclusive),
 * it is set to the max/min value of that range
 *
 * e.g. min=1.1, max=12; value=0 then after confirm, the value of the field will be 1.1
 */
@observer
export default class TargetLimitedNumberInput extends TargetNumberInput {
  // rejects numbers outside of the range:
  // capMin <= input.value <= capMax
  static propTypes = {
    capMax: PropTypes.number.isRequired,
    capMin: PropTypes.number.isRequired,
    disableMax: PropTypes.bool,
    disableMin: PropTypes.bool,
    ...TargetNumberInput.propTypes,
  }

  static defaultProps = {
    disableMax: false,
    disableMin: false,
    ...TargetNumberInput.defaultProps,
  }

  constructor(...args) {
    super(...args)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  getCapMax() {
    const { capMax } = this.props
    return Decimal(capMax === '' ? 0 : capMax)
  }

  getCapMin() {
    const { capMin } = this.props
    return Decimal(capMin === '' ? 0 : capMin)
  }

  @observable focus = false

  @action onFocus(...args) {
    const { onFocus } = this.props
    args[0].target.select()
    this.focus = true
    if (onFocus) {
      onFocus(...args)
    }
  }

  @action onBlur(...args) {
    const { onBlur } = this.props
    this.focus = false
    this.initLocalValue() // TargetNumberInput's onBlur
    if (onBlur) {
      onBlur(...args)
    }
  }

  toTarget(value) {
    const { disableMax, disableMin } = this.props;
    const capMax = this.getCapMax()
    const capMin = this.getCapMin()

    value = this.normalizeValue(value)

    // replace value is out of range
    if (!disableMin && Decimal(value).lt(capMin)) {
        // too small
        value = capMin
    } else if (!disableMax && Decimal(value).gt(capMax)) {
        // too big
        value = capMax
    }

    return value
  }

}
