import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { action, observable, computed } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import moment from 'moment'
import { theme } from 'styles'
import { Link } from 'react-router-dom'
import { getFlatStepBatchSizes, getStats, ProductionRequestProgress } from 'container/Progress/Overview'
import { flattenSteps } from 'container/Process/Edit'
import OperatorModal from './OperatorModal'
import QuantityModal from './QuantityModal'
import BatchModal from './BatchModal'
import ScanToPerformModal from 'container/ScanToPerformModal'
import Instructions from './Instructions'
import PerformExportStep from 'container/Perform/Step/Export';
import PerformImportStep from '../../Perform/Step/Import'
import PerformFormStep from 'container/Perform/Step/Form'
import PerformSplitStep from 'container/Perform/Step/Split'
import PerformPrintStep from 'container/Perform/Step/Print'
import PerformCarrierStep from 'container/Perform/Step/Carrier'
import PerformStep from 'container/Perform/Step/Step'
import { Stats } from 'container/Perform/Step/helpers'
import Decimal from 'decimal.js'
import { getNotes } from 'screen/Progress/Views/Default';
import { DateTime } from 'luxon';

// components
import CYToolbar from 'component/Toolbar'
import { SmallAvatar } from 'component/UserAvatar'
import TargetInfoModal from 'component/TargetInfoModal'
import PrintButtonModal from 'component/PrintButtonModal'
// end components

// helpers
import { SERVER_DATETIME_FORMAT } from 'helpers'
import { divideCount, getCounts } from 'helpers/counts'
import { getStockLabelInstructions } from 'helpers/print'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import sortSteps from 'helpers/sortSteps'
import { getArticleTypeName } from 'helpers/productionRequest';
import getLink from 'helpers/getLink'
import { humanReadable } from 'helpers/decimal'
// end helpers

// stores
import { Operator } from 'store/Operator'
import { GlobalValue } from 'store/GlobalValue'
import { ProductionRequest } from 'store/ProductionRequest'
import { StepStore, TYPE_EXPORT, TYPE_IMPORT } from 'store/Step'
import { StorageLocationStore } from 'store/StorageLocation'
// end stores

const minScale = 1.3
const maxScale = 2
const defaultScale = maxScale

const FullContent = styled.div`
  display: grid;
  grid-template-columns: fit-content(40%) 1fr;
  grid-template-rows: 0.1fr 0.2fr 1.3fr 0.1fr;
  gap: 0px 0px;
  grid-template-areas:
    "Tag Tag"
    "Header Header"
    "Instructions Steps"
    "Toolbar Toolbar";
  padding: 0 !important;
  max-width: unset !important;
  height: 100%;
  background-color: #e0e0e2;
`

const Tags = styled.div`
  grid-area: Tag;
  background-color: ${theme.menuColor};
  color: #FFFFFF;
  padding: 18px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  column-gap: 0.875rem;
  align-items: flex-start;
`

const Header = styled.div`
  grid-area: Header;
  background-color: #FFFFFF;
  padding: 25px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  display: grid;
  grid-template: 100% / 1fr auto 1fr;
  > h1 {
    i.icon {
      margin-right: 0.5em;
    }
    margin: 0;
  }
`

const StepContainer = styled.div`
grid-area: Steps;
`

const Toolbar = styled(CYToolbar)`
  grid-area: Toolbar;
  padding: 1em;
`

const Version = styled.span`
  margin-left: 0.25em;
  border-left: 0.1em solid rgba(0, 0, 0, 0.4);
  padding-left: 0.25em;
  color: rgba(255, 255, 255, 0.6);
`

const CloseIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.5) !important;
  cursor: pointer;
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const OperatorContainer = styled.div`
  display: flex;
  margin: -2rem 0;
  align-items: center;
  justify-content: flex-end;
  .ui.avatar.image {
    margin: 0 0.5rem 0 0 !important;
  }
`

const OperatorNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const OperatorSubtitle = styled.div`
  font-size: 80%;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  bottom: 0.25rem;
`

const StyledCount = styled.div`
  display: inline-block;
  color: #fff;
  background-color: #212121;
  font-size: 0.9rem;
  height: 1.8rem;
  min-width: 1.8rem;
  text-align: center;
  line-height: 1.8rem;
  border-radius: 0.9rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
`

class Count extends Component {
  static propTypes = {
    quantity: PropTypes.number.isRequired,
  }

  render() {
    const { quantity, ...otherProps } = this.props;

    return quantity > 0 && (
      <StyledCount data-test-production-perform-count {...otherProps}>
        {humanReadable(quantity)}
      </StyledCount>
    )
  }
}

function getRequirements({ branches, steps }, batchSizes) {
  const requirements = {}

  // eslint-disable-next-line
  for (const branch of branches) {
    Object.assign(requirements, getRequirements(branch, batchSizes))
  }

  for (let i = 0; i < steps.length; i++) {
    const prevSteps = i === 0 ? branches.map(({ steps }) => steps[steps.length - 1]) : [steps[i - 1]]

    requirements[steps[i].id] = []
    // eslint-disable-next-line
    for (const prevStep of prevSteps) {
      if (prevStep.type === 'multiplier') {
        // eslint-disable-next-line
        for (const { step, multiplier } of requirements[prevStep.id]) {
          requirements[steps[i].id].push({
            step,
            multiplier: multiplier * prevStep.multiplierStep.multiplier,
          })
        }
      } else if (prevStep.type === 'grow') {
        requirements[steps[i].id].push(...requirements[prevStep.id])
      } else {
        requirements[steps[i].id].push({
          step: prevStep,
          multiplier: 1,
        })
      }
    }

    // eslint-disable-next-line
    for (const requirement of requirements[steps[i].id]) {
      requirement.quantity = (batchSizes[steps[i].cid].after || 1) * requirement.multiplier
    }
  }

  return requirements
}

const NO_WORK_STATION_TYPES = ['multiplier', 'subprocesses']

export function getSections({ branches, steps }, workStationCode, inside = false) {
  if (steps.length > 0) {
    const subTree = { branches, steps: steps.slice(0, steps.length - 1) }
    const lastStep = steps[steps.length - 1]

    if (lastStep.workStation.code === workStationCode || (inside && NO_WORK_STATION_TYPES.includes(lastStep.type))) {
      const sections = getSections(subTree, workStationCode, true)
      sections[sections.length - 1].steps.push(lastStep)
      return sections
    } else {
      const sections = getSections(subTree, workStationCode, false)
      // eslint-disable-next-line
      for (const section of sections) {
        if (section.after === null) {
          if (lastStep.type === 'subprocesses') {
            section.steps.push(lastStep)
          } else if (!NO_WORK_STATION_TYPES.includes(lastStep.type)) {
            section.after = lastStep
          }
        }
        if (section.baseAfter === null) {
          section.baseAfter = lastStep
        }
      }
      if (inside) {
        sections.push({ branches: [], steps: [], before: [lastStep], after: null, baseAfter: null })
      }
      return sections
    }
  } else {
    const sections = []

    const subsections = []
    const before = []

    // eslint-disable-next-line
    for (const branch of branches) {
      sections.push(...getSections(branch, workStationCode, inside))
      if (inside) {
        const subsection = sections.pop()
        if (subsection.steps.length > 0) {
          subsections.push(subsection)
        } else {
          before.push(...subsection.before)
        }
      }
    }

    if (inside) {
      if (subsections.length === 0 && before.length === 0) {
        before.push(null)
      }
      sections.push({ branches: subsections, steps: [], before, after: null, baseAfter: null })
    }

    return sections
  }
}

@observer
export default class ProductionRequestProductionPerform extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
    workStationCode: PropTypes.string.isRequired,
    switchWorkStation: PropTypes.func,
    prev: PropTypes.string,
    synergyDocumentIds: PropTypes.array,
    batch: PropTypes.number,
    step: PropTypes.number,
  }

  @observable operatorBadgeGlobalValue
  @observable printerModel
  @observable singleUserWorkStationTimeoutMinutes
  @observable autoSelectPerformStep

  constructor(...args) {
    super(...args)

    this.onStepSelect = this.onStepSelect.bind(this)
    this.canSelectStep = this.canSelectStep.bind(this)
    this.onOperatorSelect = this.onOperatorSelect.bind(this)
    this.onQuantitySelect = this.onQuantitySelect.bind(this)
    this.onBatchInfoSelect = this.onBatchInfoSelect.bind(this)
    this.onAutoSelectStepBatch = this.onAutoSelectStepBatch.bind(this)
    this.onBatchSelect = this.onBatchSelect.bind(this)
    this.onPerform = this.onPerform.bind(this)
    this.onOperatorDeselect = this.onOperatorDeselect.bind(this)
    this.onStepClose = this.onStepClose.bind(this)
    // this.renderSection = this.renderSection.bind(this)
    this.renderStepPreCount = this.renderStepPreCount.bind(this)
    this.renderStepPostCount = this.renderStepPostCount.bind(this)
    this.renderAfterCount = this.renderAfterCount.bind(this)
    this.renderBeforeCount = this.renderBeforeCount.bind(this)
    this.onSelectBeforeAfter = this.onSelectBeforeAfter.bind(this)
    this.isSelectedBatchStep = this.isSelectedBatchStep.bind(this)
    this.getScannedOperator = this.getScannedOperator.bind(this)

    this.singleUserWorkStationTimeoutMinutes = new GlobalValue({ key: 'single_user_work_station_timeout_minutes' })
    this.stockLocationGlobalValue = new GlobalValue({ key: 'stock_location_print' })
    this.bestBeforePeriodGlobalValue = new GlobalValue({ key: 'default_best_before_period' })
    this.printerModel = new GlobalValue({ key: 'printer_model' })
    this.autoSelectPerformStep = isFeatureFlagEnabled('auto_select_perform_step')
  }

  sectionNodes = {}
  @observable scales = {}
  @observable storageLocationStore = new StorageLocationStore({
    relations: ['warehouse'],
    params: { limit: 'none' }
  })
  @observable autoSelectStepSuspended = false

  componentDidMount() {
    // TODO: What is this? Why is there interval running every 300 milliseconds?
    this.scaleInterval = setInterval(
      action(() => {
        // eslint-disable-next-line
        for (const [key, container] of Object.entries(this.sectionNodes)) {
          const content = container.children[0].children[1]
          const currentScale = this.scales[key] || defaultScale
          const newScale = Math.min(Math.max(container.clientWidth / content.clientWidth, minScale), maxScale)

          if (newScale !== currentScale) {
            this.scales[key] = newScale
          }
        }

        // Auto select first step if none is selected
        // and the user didn't manually suspend the autoselection.
        const { productionRequest, batch, step } = this.props
        if (!this.selectedStep && !this.autoSelectStepSuspended && !this.showInfoModal) {
          if (batch && step && productionRequest.processVersion.steps.get(parseInt(step)) !== undefined) {
            this.autoSelectStepSuspended = true
            this.onAutoSelectStepBatch(batch, step)
          } else {
            const steps = flattenSteps(sortSteps(productionRequest.processVersion.steps))
            // Go through all steps, if we encounter exactly 1 step that can be selected, we select it
            // If we encounter more and the autoselect feature flag is off, we don't select anything
            const selectableSteps = steps.filter((step) => {return this.canSelectStep(step)})
            // If there is more than 1 option to select, only do so automatically if the autoselection is disabled.
            // If there is exactly 1 option, you can always select it.
            if ((selectableSteps.length > 1 && this.autoSelectPerformStep) || selectableSteps.length === 1){
                this.onStepSelect(selectableSteps[selectableSteps.length - 1])
            }
          }
        }
      }),
      300
    )
    this.stockLocationGlobalValue.fetch()
    this.bestBeforePeriodGlobalValue.fetch()
    this.printerModel.fetch()
    if (isFeatureFlagEnabled('flexible_storage_locations')) {
      this.storageLocationStore.fetch()
    }
    this.singleUserWorkStationTimeoutMinutes.fetch()
    this.getScannedOperator()
  }

  componentWillUnmount() {
    clearInterval(this.scaleInterval)
  }

  async getScannedOperator() {
    if (this.scannedOperatorTimeout && moment().isAfter(this.scannedOperatorTimeout)) {
      this.scannedOperator = null
    } else if (this.scannedOperator === null && localStorage.getItem('scanned-operator') !== null) {
      this.scannedOperatorTimeout = moment(localStorage.getItem('scanned-operator-timeout'))
      this.scannedOperator = new Operator({
        id: parseInt(localStorage.getItem('scanned-operator'))
      }, {
        relations: ['operatorCapabilities.capability'],
      })
      await this.scannedOperator.fetch()
    }
  }

  @observable selectedStep = null
  @observable shouldSelectQuantity = false
  @observable selectedQuantity = null
  @observable selectedOperator = null
  @observable showInfoModal = false
  @observable selectedBatch = null
  @observable batchInfoSelect = false
  @observable scannedOperator = null
  @observable scannedOperatorTimeout = null

  @action onAutoSelectStepBatch(batch, step) {
    const { productionRequest } = this.props

    this.onStepSelect(productionRequest.processVersion.steps.get(parseInt(step)))
    if (!this.selectedBatch) {
      this.onBatchSelect({ batches: { [batch.split(':')[0]]: Decimal(batch.split(':')[1]) } })
    }
  }

  @action onStepSelect(step) {
    const { productionRequest } = this.props

    this.onStepClose()

    this.selectedStep = step
    this.getScannedOperator().then(() => { })

    if (!this.selectedBatch) {
      if (this.stepBatchSizes[step.cid].before === null) {
        this.selectedBatch = { production_request: productionRequest.id }
      } else {
        this.selectedBatch = null
      }
    }
    this.shouldSelectQuantity = step.type === 'split' && step.splitStep.newBatchVariableQuantityPredetermined
  }

  @action onOperatorSelect(operator) {
    this.selectedOperator = operator
    if (this.singleUser) {
      this.scannedOperator = this.selectedOperator
      this.scannedOperatorTimeout = moment().add(this.singleUserWorkStationTimeoutMinutes.value, 'minutes')
      localStorage.setItem('scanned-operator', this.scannedOperator.id)
      localStorage.setItem('scanned-operator-timeout', this.scannedOperatorTimeout)
    }
  }

  @action onQuantitySelect(quantity) {
    this.selectedQuantity = quantity
  }

  @action onBatchInfoSelect() {
    this.batchInfoSelect = true
  }

  @action onBatchSelect(batch) {
    this.selectedBatch = batch
    this.batchInfoSelect = false
  }

  @action onOperatorDeselect(operator) {
    this.scannedOperator = null
    this.scannedOperatorTimeout = null
    this.selectedOperator = null
    localStorage.removeItem('scanned-operator')
    localStorage.removeItem('scanned-operator-timeout')
  }

  onPerform(data, startedAt) {
    data = {
      operator: this.selectedOperator.id,
      started_at: startedAt.format(SERVER_DATETIME_FORMAT),
      ...this.selectedBatch, //this can be multiple batches
      ...data,
    };
    if (this.shouldSelectQuantity) {
      data.batch_size = this.selectedQuantity
    }
    return this.selectedStep.perform(data)
  }

  @action onStepClose() {
    this.selectedStep = null
    this.shouldSelectQuantity = false
    this.selectedQuantity = null
    this.selectedOperator = null
    this.selectedBatch = null
    this.batchInfoSelect = false
  }

  @computed get singleUser() {
    const { workStationCode, productionRequest } = this.props


    return productionRequest.processVersion.steps
      .filter((step) => step.workStation.code === workStationCode)
      .every((step) => step.workStation.singleUser)
  }

  @computed get steps() {
    const { productionRequest } = this.props

    return sortSteps(productionRequest.processVersion.steps)
  }

  @computed get stepBatchSizes() {
    const { productionRequest } = this.props
    return getFlatStepBatchSizes(this.steps, productionRequest.quantity)
  }

  @computed get stepCounts() {
    const { productionRequest } = this.props

    return getCounts({
      steps: this.steps,
      quantity: productionRequest.quantity,
      batches: productionRequest.batches,
      superrequestAtSubprocesses: productionRequest.superrequestAtSubprocesses,
      subrequestsFinished: productionRequest.subrequestsFinished,
    })
  }

  @computed get stepRequirements() {
    return getRequirements(this.steps, this.stepBatchSizes)
  }

  @computed get sections() {
    const { productionRequest, workStationCode } = this.props
    const sections = getSections(this.steps, workStationCode).map((tree) => {
      const steps = new StepStore({
        relations: productionRequest.processVersion.steps.__activeRelations,
      })
      steps.models = flattenSteps(tree)
      return { steps, tree }
    })
    return sections
  }

  canSelectStep(step) {
    return (
      step.type !== 'multiplier' &&
      step.type !== 'subprocesses' &&
      !Decimal(this.stepCounts[`pre_${step.id}`]).equals(Decimal(0)) && // TODO: sometimes this.stepCounts returns a 0 instead of a Decimal(0). Run cypress/integration/production/operating/outbound.spec.js to see this.
      step.workStation?.code === this.props.workStationCode
    )
  }

  renderStepPreCount(step) {
    return <Count quantity={divideCount(this.stepCounts[`pre_${step.id}`], this.stepBatchSizes[step.cid].after)} />
  }

  renderStepPostCount(step) {
    return (
      <Count
        data-test-post-step-count
        quantity={divideCount(this.stepCounts[`post_${step.id}`], this.stepBatchSizes[step.cid].after)}
      />
    )
  }

  renderAfterCount(_, step) {
    return <Count quantity={divideCount(this.stepCounts[`total_pre_${step.id}`], this.stepBatchSizes[step.cid].before)} />
  }

  renderBeforeCount(step) {
    return <Count quantity={divideCount(this.stepCounts[`total_post_${step.id}`], this.stepBatchSizes[step.cid].after)} />
  }

  onSelectBeforeAfter(step) {
    const { switchWorkStation } = this.props
    switchWorkStation(step.workStation)
  }

  // renderSection({ steps, tree }, i) {
  //   const { switchWorkStation, productionRequest, workStationCode } = this.props

  //   return (
  //     <Steps
  //       key={i}
  //       data-test-production-perform-steps={i + 1}
  //       steps={steps}
  //       tree={tree}
  //       getStepColor={(step) => COLORS[step.workStation.productionLineVersion.productionLine.color]}
  //       onSelect={this.onStepSelect}
  //       canSelect={this.canSelectStep}
  //       scale={this.scales[i] || defaultScale}
  //       renderTop={this.renderStepPreCount}
  //       renderLineTo={this.renderStepPostCount}
  //       renderTopBefore={this.renderBeforeCount}
  //       renderTopAfter={this.renderAfterCount}
  //       onSelectBefore={switchWorkStation && this.onSelectBeforeAfter}
  //       onSelectAfter={switchWorkStation && this.onSelectBeforeAfter}
  //       innerRef={(ref) => (this.sectionNodes[i] = ref)}
  //     />
  //   )
  // }


  @computed get stats() {
    const { productionRequest } = this.props
    return getStats(this.steps, productionRequest)
  }

  isSelectedBatchStep(step) {
    if (step.type === 'multiplier') {
      return false
    }

    const { productionRequest } = this.props

    const target = productionRequest.batches.get(parseInt(Object.keys(this.selectedBatch.batches)[0]))
    const lastStep = productionRequest.processVersion.steps.get(target.lastStep.id)

    return step.id === lastStep.nextStep.id
  }

  @computed get warehouses() {
    const { productionRequest } = this.props

    if (['buy', 'make'].includes(productionRequest.processVersion.batchType.type) && isFeatureFlagEnabled('flexible_storage_locations')) {
      const _warehouses = this.storageLocationStore.map(({ warehouse }) => warehouse)
      const uniqueWarehouses = Array.from(new Set(_warehouses.map(w => w.id))).map(id => {
        return _warehouses.find(a => a.id === id)
      })
      return uniqueWarehouses
    }
    return productionRequest.processVersion.batchType.articleType.warehouses.map(({ warehouse }) => warehouse)
  }

  @computed get storageLocations() {
    const { productionRequest } = this.props

    if (['buy', 'make'].includes(productionRequest.processVersion.batchType.type) && isFeatureFlagEnabled('flexible_storage_locations')) {
      return this.storageLocationStore.models
    }
    return productionRequest.processVersion.batchType.articleType.storageLocations.map(({ storageLocation }) => storageLocation)
  }

  getWarehouseAndStorageLocation(batchId, value) {
    const { productionRequest } = this.props
    const batch = productionRequest.batches.get(batchId)
    // eslint-disable-next-line
    for (const batchUsing of batch.batchUsings.models) {
      value = this.getWarehouseAndStorageLocation(batchUsing.usedBatch.id, value)
    }
    // eslint-disable-next-line
    for (const performance of batch.performances.models) {
      if (performance.reworked) {
        continue
      }
      if (value.timestamp !== null && value.timestamp.isAfter(performance.createdAt)) {
        continue
      }
      // eslint-disable-next-line
      for (const detail of performance.details.models) {
        if (!detail.storageLocation.isNew) {
          value = {
            warehouse: detail.storageLocation.warehouse,
            storageLocation: detail.storageLocation,
            timestamp: performance.createdAt,
          }
        }
      }
    }
    return value
  }

  @computed get _currentWarehouseAndStorageLocation() {
    const { productionRequest } = this.props

    let warehouse = null
    if (productionRequest.processVersion.batchType.type === 'buy' && !productionRequest.inShipmentLine.inShipment.purchaseOrder.warehouse.isNew) {
      warehouse = productionRequest.inShipmentLine.inShipment.purchaseOrder.warehouse
    } else if (productionRequest.processVersion.batchType.type === 'receive_order' && !productionRequest.inShipment.purchaseOrder.warehouse.isNew) {
      warehouse = productionRequest.inShipment.purchaseOrder.warehouse
    } else if (productionRequest.processVersion.batchType.type === 'sell' && !productionRequest.outShipmentLine.outShipment.salesOrder.warehouse.isNew) {
      warehouse = productionRequest.outShipmentLine.outShipment.salesOrder.warehouse
    } else if (productionRequest.processVersion.batchType.type === 'pick_order' && !productionRequest.outShipment.salesOrder.warehouse.isNew) {
      warehouse = productionRequest.outShipment.salesOrder.warehouse
    } else if (productionRequest.processVersion.batchType.type === 'make' && !productionRequest.productionOrder.warehouse.isNew) {
      warehouse = productionRequest.productionOrder.warehouse
    }

    let value = {
      warehouse: warehouse,
      storageLocation: null,
      timestamp: null,
    }
    if (this.selectedBatch && this.selectedBatch.batches) {
      // eslint-disable-next-line
      for (const batchId of Object.keys(this.selectedBatch.batches)) {
        value = this.getWarehouseAndStorageLocation(parseInt(batchId), value)
      }
    }
    return value
  }

  @computed get currentWarehouse() {
    return this._currentWarehouseAndStorageLocation.warehouse
  }

  @computed get currentStorageLocation() {
    return this._currentWarehouseAndStorageLocation.storageLocation
  }
  @computed get maxPerformCount() {
    const { productionRequest } = this.props
    return Math.min(
      divideCount(
        this.stepCounts[`pre_${this.selectedStep.id}`],
        this.shouldSelectQuantity ? this.selectedQuantity : this.stepBatchSizes[this.selectedStep.cid].after,
      ),
      ...(
        Object.entries(this.selectedBatch.batches ?? {})
          .map(([batchId, quantity]) => Math.floor(
            productionRequest.batches.get(parseInt(batchId)).quantityRemaining
            / quantity
          ))
      ),
    );

  }

  @computed get selectedPerformCount() {
    const { productionRequest } = this.props
    //return the minimum between the selected step quantity and the available batch input
    let selectQuantity
    if(this.shouldSelectQuantity){
      selectQuantity = this.selectedQuantity ?? 1
    }
    else{
      selectQuantity = this.batchSize ? Decimal(this.batchSize).toNumber() : 1
    }
    return Math.min(
      selectQuantity,
    ...(
      Object.entries(this?.selectedBatch?.batches ?? {})
        .map(([batchId]) => Math.floor(
          productionRequest.batches.get(parseInt(batchId)).quantityRemaining
        ))
    ))
  }

  @computed get batchSize() {
    const { productionRequest } = this.props
    if (!this.selectedStep) {
      return undefined
    } else if (this.shouldSelectQuantity) {
      return this.selectedQuantity
    } else if (this.selectedStep.type !== 'split' && this.selectedBatch !== null && this.selectedBatch.batches !== undefined) {
      return Object.values(this.selectedBatch.batches)[0]
    } else {
      return this.stepBatchSizes[this.selectedStep.cid].after ?? productionRequest.quantity
    }
  }

  quantityAtStepId(stepId) {
    const { productionRequest } = this.props

    let quantity = Decimal(0)
    // eslint-disable-next-line
    for (const batch of productionRequest.batches.filter((batch) => batch.lastStep.id === stepId)) {
      if (batch.finalizedAt != null) {
        quantity = quantity.add(batch.quantity)
      } else {
        quantity = quantity.add(batch.quantityRemaining)
      }
    }
    return quantity
  }

  @computed get predeterminedQuantity() {
    const { productionRequest } = this.props

    // TODO: tested by cypress/integration/production/operating/variableBatchPredeterminedQuantity.spec.js, move decimals to model.
    let remainingQuantity = Decimal(productionRequest.quantity)

    let step = this.selectedStep.id
    while (step !== null) {
      remainingQuantity = remainingQuantity.sub(this.quantityAtStepId(step))
      step = productionRequest.processVersion.steps.get(step).nextStep.id
    }

    // eslint-disable-next-line
    for (const { step, multiplier } of this.stepRequirements[this.selectedStep.id]) {
      remainingQuantity = Decimal.min(remainingQuantity, this.quantityAtStepId(step.id).div(multiplier))
    }

    return remainingQuantity
  }

  renderTags() {
    const { productionRequest, workStationCode } = this.props

    const inShipment = productionRequest.inShipment
    const purchaseOrder = inShipment.purchaseOrder
    const exactPurchaseOrder = purchaseOrder.exactPurchaseOrder
    const unit4PurchaseOrder = purchaseOrder.unit4PurchaseOrder
    const exactGlobePurchaseOrder = purchaseOrder.exactGlobePurchaseOrder

    const outShipment = productionRequest.outShipment
    const salesOrder = outShipment.salesOrder
    const exactSalesOrder = salesOrder.exactSalesOrder
    const unit4Order = salesOrder.unit4Order
    const exactGlobeSalesOrder = salesOrder.exactGlobeSalesOrder

    const transfer = productionRequest.warehouseTransfer

    const productionOrder = productionRequest.productionOrder
    const exactShopOrder = productionOrder.exactShopOrder
    const exactGlobeProductionOrder = productionOrder.exactGlobeProductionOrder

    const stockCount = productionRequest.stockCount

    const isWorkStation = workStationCode !== null

    return (
      <b data-test-order-tags style={{ marginRight: '10px' }}>
        {(!productionRequest.isNew) &&
          getLink(productionRequest, isWorkStation, {
            hover: (
              <>
                {t('productionRequest.overview.productionRequestID')}: {productionRequest && productionRequest.id}{' '}
                <br />
                {t('productionRequest.field.project.label')}:{' '}
                {productionRequest.project && productionRequest.project.code}
              </>
            ),
          })}
        {!transfer.isNew && (
          getLink(transfer, isWorkStation, {
            hover: (
              <>
                {t('productionRequest.overview.warehouseTransferID')}:{' '}
                {transfer && transfer.id}
              </>
            ),
          })
        )}
        {!inShipment.isNew &&
          getLink(inShipment, isWorkStation, {
            hover: (
              <>
                {t('productionRequest.overview.inShipmentId')}:{' '}
                {inShipment && inShipment.id}
              </>
            ),
          })}
        {!purchaseOrder.isNew && (
          <>
            {getLink(purchaseOrder, isWorkStation, {
              hover: (
                <>
                  {t('productionRequest.overview.purchaseOrderNumber')}:{' '}
                  {purchaseOrder && purchaseOrder.id}{' '}
                </>
              ),
            })}
          </>
        )}
        {!exactPurchaseOrder.isNew &&
          getLink(exactPurchaseOrder, isWorkStation, {
            hover: (
              <>{t('productionRequest.overview.exactPurchaseOrder', { order: exactPurchaseOrder.number })}</>
            ),
          })}
        {!unit4PurchaseOrder.isNew && getLink(unit4PurchaseOrder, isWorkStation)}
        {!exactGlobePurchaseOrder.isNew && getLink(exactGlobePurchaseOrder, isWorkStation)}
        {!outShipment.isNew &&
          getLink(outShipment, isWorkStation, {
            hover: (
              <>
                {t('productionRequest.overview.outShipmentId')}:{' '}
                {outShipment && outShipment.id}
              </>
            ),
          })}
        {!salesOrder.isNew &&
          getLink(salesOrder, isWorkStation, {
            hover: (
              <>
                {t('productionRequest.overview.salesOrderNumber')}:{' '}
                {salesOrder && salesOrder.id}
              </>
            ),
          })}
        {!exactSalesOrder.isNew &&
          getLink(exactSalesOrder, isWorkStation, {
            hover: <>{t('productionRequest.overview.exactSalesOrder', { order: exactSalesOrder.number })}</>,
          })}
        {!unit4Order.isNew && getLink(unit4Order, isWorkStation)}
        {!exactGlobeSalesOrder.isNew && getLink(exactGlobeSalesOrder, isWorkStation)}
        {!productionOrder.isNew && (
          <>
            {getLink(productionOrder, isWorkStation, {
              hover: (
                <>
                  {t('productionRequest.overview.productionOrderNumber')}:{' '}
                  {productionOrder && productionOrder.id}
                </>
              ),
            })}
          </>
        )}
        {!exactShopOrder.isNew &&
          getLink(exactShopOrder, isWorkStation, {
            hover: <>{t('productionRequest.overview.exactShopOrder', { order: exactShopOrder.number })}</>,
          })}
        {!exactGlobeProductionOrder.isNew && getLink(exactGlobeProductionOrder, isWorkStation)}
        {!stockCount.isNew &&
          getLink(stockCount, isWorkStation, {
            hover: (
              <>
                {t('productionRequest.overview.stockCountNumber')}:{' '}
                {stockCount && stockCount.id}
              </>
            ),
          })}
        {!productionRequest.processVersion.batchType.articleType.isNew && getLink(productionRequest.processVersion.batchType.articleType, isWorkStation)}
        {!productionRequest.nestRequest.isNew && productionRequest.nestRequest.getLabel()}
      </b>
    )
  }

  async getStockLocationLabelInstructions() {
    const { productionRequest } = this.props
    const pr = new ProductionRequest(
      { id: productionRequest.id },
      {
        relations: [
          'processVersion.batchType.articleType.exactItem',
          'processVersion.batchType.articleType.metavalues.metafield',
          'processVersion.batchType.articleType.metafields',
          'processVersion.batchType.articleType.classification',
          'processVersion.batchType.articleType.warehouses.warehouse.storageLocations',
          'inShipment.purchaseOrder.warehouse.storageLocations',
          'inShipmentLine.inShipment.purchaseOrder.warehouse.storageLocations',
          'outShipment.salesOrder.warehouse.storageLocations',
          'outShipmentLine.outShipment.salesOrder.warehouse.storageLocations',
          'productionOrder.warehouse.storageLocations',
        ],
      }
    )
    pr.setFetchParams({
      where: (
        `in_shipment.purchase_order.warehouse.storage_locations(article_type_storage_locations.article_type=${productionRequest.processVersion.batchType.articleType.id}),` +
        `in_shipment_line.in_shipment.purchase_order.warehouse.storage_locations(article_type_storage_locations.article_type=${productionRequest.processVersion.batchType.articleType.id}),` +
        `out_shipment.sales_order.warehouse.storage_locations(article_type_storage_locations.article_type=${productionRequest.processVersion.batchType.articleType.id}),` +
        `out_shipment_line.out_shipment.sales_order.warehouse.storage_locations(article_type_storage_locations.article_type=${productionRequest.processVersion.batchType.articleType.id}),` +
        `production_order.warehouse.storage_locations(article_type_storage_locations.article_type=${productionRequest.processVersion.batchType.articleType.id}),` +
        `process_version.batch_type.article_type.warehouses.warehouse.storage_locations(article_type_storage_locations.article_type=${productionRequest.processVersion.batchType.articleType.id})`
      )
    })

    await Promise.all([
      pr.fetch(),
      this.printerModel.fetch(),
    ])

    let warehouse = null
    if (pr.processVersion.batchType.type === 'buy' && !pr.inShipmentLine.inShipment.purchaseOrder.warehouse.isNew) {
      warehouse = pr.inShipmentLine.inShipment.purchaseOrder.warehouse
    } else if (pr.processVersion.batchType.type === 'receive_order' && !pr.inShipment.purchaseOrder.warehouse.isNew) {
      warehouse = pr.inShipment.purchaseOrder.warehouse
    } else if (pr.processVersion.batchType.type === 'sell' && !pr.outShipmentLine.outShipment.salesOrder.warehouse.isNew) {
      warehouse = pr.outShipmentLine.outShipment.salesOrder.warehouse
    } else if (pr.processVersion.batchType.type === 'pick_order' && !pr.outShipment.salesOrder.warehouse.isNew) {
      warehouse = pr.outShipment.salesOrder.warehouse
    } else if (pr.processVersion.batchType.type === 'make' && !pr.productionOrder.warehouse.isNew) {
      warehouse = pr.productionOrder.warehouse
    } else {
      warehouse = pr.productionOrder.warehouse
    }

    const warehouse_locations = warehouse.storageLocations.map((sl) => sl.code).join(' | ')

    const bbd = DateTime.local().plus({ days: this.bestBeforePeriodGlobalValue.value })

    return getStockLabelInstructions(
      this.stockLocationGlobalValue.value,
      pr.processVersion.batchType.articleType,
      warehouse.code, warehouse_locations,
      pr.productionOrder, bbd.toFormat('yyyy-L-d')
    )
  }

  renderActions() {
    const { productionRequest } = this.props

    return (
      <ActionsContainer>
        <PrintButtonModal
          title={'stock_location_print'}
          getInstructions={() => this.getStockLocationLabelInstructions()}
          trigger={<Button primary
            data-test-stock-location-print-button
            icon="print"
            size="massive"
          />}
        />
        <Button
          primary
          data-test-show-batch-info-modal-button
          icon="square-info"
          size="massive"
          style={{ marginLeft: '20px' }}
          onClick={this.onBatchInfoSelect}
        />
        <ScanToPerformModal
          history={this.props.history}
          operator={this.scannedOperator}
          batches={productionRequest.batches}
          onStepSelect={this.onAutoSelectStepBatch}
          onShow={(open) => this.showInfoModal = open}
          triggerProps={{
            icon: 'barcode-read',
            size: 'massive',
            style: { marginLeft: '20px' },
          }}
        />
        <OperatorContainer>
          {this.scannedOperator && (
            <React.Fragment>
              <SmallAvatar user={this.scannedOperator} />
              <OperatorNameContainer>
                <div data-test-scanned-operator>
                  {this.scannedOperator.fullName} <CloseIcon data-test-deselect-operator name="close" onClick={this.onOperatorDeselect} />
                </div>
                <OperatorSubtitle>
                  {t('workStation.production.operator.expiresAt', {
                    at: this.scannedOperatorTimeout.format('H:mm'),
                  })}
                </OperatorSubtitle>
              </OperatorNameContainer>
            </React.Fragment>
          )}
        </OperatorContainer>
      </ActionsContainer>
    )
  }

  renderStepContent(link) {
    const { productionRequest } = this.props

    const predeterminedQuantity = this.stepBatchSizes[this.selectedStep.cid].useOrderSize ? this.predeterminedQuantity : this.batchSize
    let nodeContent
    switch (this.selectedStep.type) {
      case 'print':
        nodeContent = (
          <PerformPrintStep
            productionRequest={productionRequest}
            step={this.selectedStep}
            maxPerformCount={this.maxPerformCount}
            selectedPerformCount={this.selectedPerformCount}
            onClose={this.onStepClose}
            onPerform={this.onPerform}
            prev={link}
            stats={this.stats}
          />
        )
        break
      case 'form':
        nodeContent = (
          <PerformFormStep
            productionRequest={productionRequest}
            step={this.selectedStep}
            batch={this.selectedBatch}
            batchSize={this.batchSize}
            warehouses={this.warehouses}
            currentWarehouse={this.currentWarehouse}
            storageLocations={this.storageLocations}
            stats={this.stats}
            onClose={this.onStepClose}
            prev={link}
            onPerform={this.onPerform}
            predeterminedQuantity={predeterminedQuantity}
            operator={this.selectedOperator}
            maxPerformCount={this.maxPerformCount}
            selectedPerformCount={this.selectedPerformCount}
          />
        )
        break
      case 'split':
        nodeContent = (
          <PerformSplitStep
            productionRequest={productionRequest}
            step={this.selectedStep}
            batch={this.selectedBatch}
            batchSize={this.batchSize}
            warehouses={this.warehouses}
            currentWarehouse={this.currentWarehouse}
            storageLocations={this.storageLocations}
            stats={this.stats}
            maxPerformCount={this.maxPerformCount}
            selectedPerformCount={this.selectedPerformCount}
            onClose={this.onStepClose}
            prev={link}
            onPerform={this.onPerform}
            predeterminedQuantity={predeterminedQuantity}
            operator={this.selectedOperator}
          />
        )
        break
      case 'carrier':
        nodeContent = (
          <PerformCarrierStep
            productionRequest={productionRequest}
            step={this.selectedStep}
            prev={link}
            stats={this.stats}
            onPerform={this.onPerform}
            onClose={this.onStepClose}
            maxPerformCount={this.maxPerformCount}
            selectedPerformCount={this.selectedPerformCount}
          />
        )
        break
      case TYPE_EXPORT:
        nodeContent = (
          <PerformExportStep
            productionRequest={productionRequest}
            onPerform={this.onPerform}
            onClose={this.onStepClose}
            step={this.selectedStep}
            batch={this.selectedBatch}
            prev={link}
            stats={this.stats}
            maxPerformCount={this.maxPerformCount}
            selectedPerformCount={this.selectedPerformCount}
          />
        )
        break;
      case TYPE_IMPORT:
        nodeContent = (
          <PerformImportStep
            productionRequest={productionRequest}
            onPerform={this.onPerform}
            onClose={this.onStepClose}
            step={this.selectedStep}
            batch={this.selectedBatch}
            prev={link}
            stats={this.stats}
            operator={this.selectedOperator}
            maxPerformCount={this.maxPerformCount}
            selectedPerformCount={this.selectedPerformCount}
          />
        )
        break
      default:
        nodeContent = (
          <PerformStep
            productionRequest={productionRequest}
            step={this.selectedStep}
            prev={link}
            stats={this.stats}
            onPerform={this.onPerform}
            onClose={this.onStepClose}
            maxPerformCount={this.maxPerformCount}
            selectedPerformCount={this.selectedPerformCount}
          />
        )
    }

    return (
      <StepContainer data-test-perform-modal>
        {nodeContent}
      </StepContainer>)

  }

  render() {
    const { productionRequest, prev, synergyDocumentIds, workStationCode, switchWorkStation } = this.props

    const mainExpandedLink = '/operations/production-request/overview' + (!productionRequest.superrequest.isNew ? `/${productionRequest.superrequest.id}` : '')

    let reference = null
    if (!productionRequest.productionOrder.isNew && !productionRequest.productionOrder.exactShopOrder.isNew) {
      reference = productionRequest.productionOrder.exactShopOrder.reference
    } else if (!productionRequest.inShipment.isNew && !productionRequest.inShipment.purchaseOrder.isNew) {
      reference = productionRequest.inShipment.purchaseOrder.reference
    } else if (!productionRequest.inShipmentLine.isNew && !productionRequest.inShipmentLine.inShipment.isNew && !productionRequest.inShipmentLine.inShipment.purchaseOrder.isNew) {
      reference = productionRequest.inShipmentLine.inShipment.purchaseOrder.reference
    } else if (!productionRequest.outShipment.isNew && !productionRequest.outShipment.salesOrder.isNew) {
      reference = productionRequest.outShipment.salesOrder.reference
    } else if (!productionRequest.outShipmentLine.isNew && !productionRequest.outShipmentLine.outShipment.isNew && !productionRequest.outShipmentLine.outShipment.salesOrder.isNew) {
      reference = productionRequest.outShipmentLine.outShipment.salesOrder.reference
    }

    const showStepContent = this.selectedStep && this.selectedOperator && (!this.shouldSelectQuantity || this.selectedQuantity) && this.selectedBatch
    const showInstructions = showStepContent && (
      this.selectedStep.sections.length > 0 ||
      synergyDocumentIds.length > 0 ||
      productionRequest.productionOrder.exactShopOrder.exactDocuments.length > 0 ||
      productionRequest.processVersion.batchType.articleType?.metavalues?.length > 0
    )

    let batchModalRequirements = null
    if (!this.selectedStep) {
      batchModalRequirements = null
    } else if (!this.selectedQuantity || !this.stepBatchSizes[this.selectedStep.cid].after) {
      batchModalRequirements = this.stepRequirements[this.selectedStep.id]
    } else {
      batchModalRequirements = this.stepRequirements[this.selectedStep.id].map(({ quantity, ...requirement }) => ({
        ...requirement,
        quantity: quantity * this.selectedQuantity / this.stepBatchSizes[this.selectedStep.cid].after,
      }))
    }

    return (
      <FullContent>
        <Tags>
          {this.renderTags()}
          <b>{getArticleTypeName(productionRequest)}</b>
          {reference && <span><i>Reference: </i>{reference}</span>}
          <div style={{ display: 'inline' }}>
            <i>Process: </i>{productionRequest.processVersion.batchType.description} {' | '}
            <Version data-test-version-number>
              {t('workStation.production.version', { version: productionRequest.processVersion.version })}
            </Version>
          </div>
        </Tags>
        <Header>
          <h1 style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={prev ?? mainExpandedLink}><Button primary data-test-prev-button icon="arrow left" size="massive" /></Link>
          </h1>
          <ProductionRequestProgress
            productionRequest={productionRequest}
            onSelectStep={(step) => this.onStepSelect(step)}
            canSelectStep={(step) => this.canSelectStep(step)}
            selectedStep={this.selectedStep}
            workStationCode={workStationCode}
            onSelectBefore={switchWorkStation && this.onSelectBeforeAfter}
            onSelectAfter={switchWorkStation && this.onSelectBeforeAfter}
          />
          {this.renderActions()}
        </Header>
        {this.selectedStep && !this.selectedOperator && (
          <OperatorModal
            onSelect={this.onOperatorSelect}
            onClose={() => {
              // Suspend the autoselection of the first/next step
              this.autoSelectStepSuspended = true
              this.onStepClose()
            }}
            capabilities={this.selectedStep.capabilities}
            scannedOperator={this.scannedOperator}
          />
        )}
        {this.selectedStep && this.selectedOperator && this.shouldSelectQuantity && !this.selectedQuantity && (
          <QuantityModal
            quantity={
              this.selectedStep.splitStep.newBatchVariableUseOrderSize
                ? this.predeterminedQuantity
                : this.stepCounts[`pre_${this.selectedStep?.id}`] &&
                divideCount(this.stepCounts[`pre_${this.selectedStep?.id}`], this.stepBatchSizes[this.selectedStep?.cid]?.after)
            }
            onSelect={this.onQuantitySelect}
            onClose={() => {
              // Suspend the autoselection of the first/next step
              this.autoSelectStepSuspended = true
              this.onStepClose()
            }}
          />
        )}
        {((this.selectedStep && this.selectedOperator && (!this.shouldSelectQuantity || this.selectedQuantity)) || this.batchInfoSelect) && !this.selectedBatch && (
          <BatchModal
            split={this.selectedStep ? this.selectedStep.type === 'split' : undefined}
            batchSize={this.batchSize}
            onSelect={this.onBatchSelect}
            onClose={() => {
              this.autoSelectStepSuspended = true
              this.onStepClose()
            }}
            requirements={batchModalRequirements}
            batches={productionRequest.batches}
          />
        )}
        {showStepContent && (
          this.renderStepContent(mainExpandedLink)
        )}
        {showInstructions && (
          <Instructions
            sections={this.selectedStep.sections}
            open={this.selectedStep.sections.filter(({ important }) => important).length > 0}
            notes={getNotes(productionRequest)}
            synergyDocumentIds={synergyDocumentIds}
            documents={productionRequest.productionOrder.exactShopOrder.exactDocuments}
            productionRequest={productionRequest}
          />
        )}
        {!this.selectedStep && this.selectedBatch && (
          <TargetInfoModal
            open
            target={productionRequest.batches.get(parseInt(Object.keys(this.selectedBatch.batches)[0]))}
            onClose={this.onStepClose}
            scale={1.5}
            canSelectStep={this.isSelectedBatchStep}
            onSelectStep={this.onStepSelect}
          />
        )}
        {!showStepContent && (
          <Toolbar>
            <Stats>
              <span>{t('progress.productionLine.stat.todo')} <span data-test-production-progress-todo>{humanReadable(this.stats.quantityTodo)}</span></span>
              <span>{t('progress.productionLine.stat.inProgress')} <span data-test-production-progress-ip>{humanReadable(this.stats.quantityInProgress)}</span></span>
              <span>{t('progress.productionLine.stat.done')} <span data-test-production-progress-done>{humanReadable(this.stats.quantityDone)}</span></span>
            </Stats>
          </Toolbar>
        )}
      </FullContent>
    )
  }
}
